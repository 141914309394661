import React, { useEffect, useState } from "react";
import FormacionProfesional from "../../components/CommonEnfermeroAdministrador/enfermero/FormacionProfesional";
import ExperienciaLab from "../../components/CommonEnfermeroAdministrador/enfermero/ExperienciaLab";
import Estudios from "../../components/CommonEnfermeroAdministrador/enfermero/Estudios";
import HabilidadesProfesionales from "../../components/CommonEnfermeroAdministrador/HabilidadesProfesionales";
import AreasEspecial from "../../components/CommonEnfermeroAdministrador/enfermero/AreasEspecial";
import { useFormik } from "formik";
import * as Yup from "yup";
import { validaDates } from "../../helpers/formularios/Validation";
import { showFormErrors } from "../../helpers/showFormErrors";
import { getAreasEsp, getHabilidades, nivelesResource, usuariosResource } from "../../helpers/configRoutes/backend_helper";
import EstudiosEdit from "../../components/CommonEnfermeroAdministrador/enfermero/EstudiosEdit";
import ExperienciaLabEdit from "../../components/CommonEnfermeroAdministrador/enfermero/ExperienciaLabEdit";
import CustomButton from "../../components/form/CustomButton";


const EstudiosAcreditaciones = ({ item, editItem, validationItem, setSaveEstatus,setEditItem }) => {
    const [estatusValidacion, setEstatusValidacion] = useState(2);
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: validationItem.values.acreditaciones,
        validationSchema: Yup.object({
            estudios: Yup.string().required('Campo requerido'),
            casa_estudios: Yup.string().required('Campo requerido'),
            cedula_profesional: Yup.string()
                .required('Campo requerido')
                .matches(/^\d{6,8}$/, 'La cedula debe tener entre 8 y 10 dígitos'),
            graduacion_fecha: validaDates(0, 'Tu graduacion tiene que ser anterior a la fecha de hoy '),
            descripcion_profesional: Yup.string().required('Campo requerido'),
            experiencias_laborales: Yup.array().of(
                Yup.object().shape({
                    cargo: Yup.string().required('Campo requerido'),
                    actividades: Yup.string().required('Campo requerido'),
                    fecha_inicio: validaDates(0, 'Tu Fecha de inicio tiene que ser anterior a la fecha de hoy '),
                    fecha_fin: Yup.date()
                        .required('Campo requerido')
                        .test('fecha-mayor', 'La fecha de término debe ser posterior a la fecha de inicio', function (value) {
                            const fechaInicio = this.parent.fecha_inicio;
                            return fechaInicio < value;
                        }),

                })
            ).min(1, 'Debe haber al menos una formación académica'),
            formacion_academica: Yup.array().of(
                Yup.object().shape({
                    institucion: Yup.string().required('Campo requerido'),
                    especialidad_id: Yup.number().required('Campo requerido'),
                    fecha_inicio: validaDates(0, 'Tu Fecha de inicio tiene que ser anterior a la fecha de hoy '),
                    fecha_fin: Yup.date()
                        .required('Campo requerido')
                        .test('fecha-mayor', 'La fecha de término debe ser posterior a la fecha de inicio', function (value) {
                            const fechaInicio = this.parent.fecha_inicio;
                            return fechaInicio < value;
                        }),
                    formacion: Yup.boolean(),
                })
            ).min(1, 'Debe haber al menos una formación académica'),
            formacion_especialidades: Yup.array().of(
                Yup.object().shape({
                    institucion: Yup.string().required('Campo requerido'),
                    especialidad_id: Yup.number().required('Campo requerido'),
                    fecha_inicio: validaDates(0, 'Tu Fecha de inicio tiene que ser anterior a la fecha de hoy '),
                    fecha_fin: Yup.date()
                        .required('Campo requerido')
                        .test('fecha-mayor', 'La fecha de término debe ser posterior a la fecha de inicio', function (value) {
                            const fechaInicio = this.parent.fecha_inicio;
                            return fechaInicio < value;
                        }),
                    formacion: Yup.boolean(),
                })
            ),
            servicios: Yup.array().min(1)
        }),
        onSubmit: async (values, { setErrors }) => {
            setEstatusValidacion(1)
            validationItem.setFieldValue('acreditaciones', values);

            const response = await usuariosResource('put', validationItem.values, validationItem.values.id)
            if (response.status) {
                setSaveEstatus(false)
                setEditItem(false); // Finaliza el modo edición
            }
            setEstatusValidacion(3);
            setSaveEstatus(true)
            
        },
    });

    useEffect(() => {
        if (estatusValidacion) {
            setEstatusValidacion(2)
            setEstatusValidacion(false)
        }
    }, [validation.values]);

    const [categorias, setCategorias] = useState([]);
    const [habilidades, setHabilidades] = useState([]);
    const [estudios, setEstudios] = useState([]);
    useEffect(() => {
        const getCatalogos = async () => {
            const response = await getAreasEsp();
            setCategorias(response.categorias)
            const resposeHabilidades = await getHabilidades();
            setHabilidades(resposeHabilidades.habilidades)
            const responseEstudios = await nivelesResource('get')
            setEstudios(responseEstudios.niveles)
        }
        if (editItem) {
            getCatalogos()
        }
    }, [editItem]);

    return (
        <>
            {editItem && (
                // Botón visible solo si editItem es true
                <form
                    onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        showFormErrors(validation);
                        return false;
                    }}
                >
                    <div className="w-full flex justify-end mt-[-50px]">
                        <CustomButton
                            className="bg-blue-500 text-white font-semibold py-2 px-4 rounded"
                            type={'submit'}
                        >
                            Guardar información
                        </CustomButton>
                    </div>
                </form>
            )}
            <div className="tab-content tab-content-active space-y-8 bg-white p-6 rounded-md shadow-md">
                <div>
                    <FormacionProfesional item={item} validation={validation} editItem={editItem} estudios={estudios} />
                </div>
                <div>
                    {editItem ?
                        <ExperienciaLabEdit item={item} validation={validation} editItem={editItem} />
                        :
                        <ExperienciaLab item={item} validation={validation} editItem={editItem} />
                    }

                </div>
                <div >
                    {editItem ?
                        <>
                            <EstudiosEdit item={item} validation={validation} editItem={editItem} />
                        </>
                        :
                        <>
                            <Estudios item={item} validation={validation} editItem={editItem} />
                        </>
                    }
                </div>
                <div >
                    <HabilidadesProfesionales item={item} validation={validation} editItem={editItem} habilidades={habilidades} />
                </div>
                <div>
                    <AreasEspecial item={item} validation={validation} editItem={editItem} categorias={categorias} />
                </div>
                
            </div>
        </>
    );
};

export default EstudiosAcreditaciones;
