import React, { useState } from "react";
import CustomButton from "../../components/form/CustomButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { set_paciente } from "../../store/pacientes/actions";


const Pacientes = ({ item }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [cliente, setCliente] = useState([]);
    const pacientesState = useSelector((state) => state.PacientesState);
    console.log(pacientesState.paciente)

    const getPaciente = (paciente) =>{
        dispatch(set_paciente(paciente))
        navigate(`/paciente/${item.id}/${paciente?.id}`)
    }
    return (
        <>
            <div className="tab-content tab-content-active space-y-8 bg-white p-6 rounded-md shadow-md">
            <div className='flex flex-row w-full flex-wrap p-4'>
                    {item?.pacientes && item?.pacientes.length > 0 ? (
                        item.pacientes.map((p, index) => (
                            <div className="flex w-1/5 items-center select-none" key={p.id || index}>
                                <div className="flex flex-col w-full bg-gray-200 m-2 p-2 rounded relative">
                                    <div className="flex flex-row w-full">
                                        <div className="flex w-1/3">
                                            <img
                                                src={p?.perfilImg || "https://static.vecteezy.com/system/resources/previews/001/886/209/non_2x/doctor-medical-cartoon-design-vector.jpg"}
                                                alt="nurse img"
                                                className="object-fill h-12 rounded-full mx-auto"
                                            />
                                        </div>
                                        <div className="flex flex-col w-2/3">
                                            <p className="font-bold text-base">{p?.nombre}</p>
                                            <p className="text-xs">Nombre</p>
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <p className="font-bold text-base">{p?.apellidos}</p>
                                        <p className="text-xs">Apellidos</p>
                                    </div>
                                    
                                    <div className="flex flex-col w-full">
                                        <p className="font-bold text-base">{p?.especialidad_nombre}</p>
                                        <p className="text-xs">Requiere especialista en</p>
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <p className="font-bold text-base">{p?.servicio_estatus}</p>
                                        <p className="text-xs">Estatus de servicio</p>
                                    </div>
                                   
                                    <div className="flex flex-col w-full pt-2">
                                    <CustomButton
                                            onClick={() => getPaciente(p)}
                                            className="bg-black hover:bg-slate-600 mx-2 text-white text-center px-1 rounded"
                                        >
                                            Ver usuario
                                        </CustomButton>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>No clients found</p>
                    )}

                </div>


            </div>
        </>
    );
}
export default Pacientes;