import React from "react";
import { dangerToast } from "../../helpers/Toast";

const HabilidadesProfesionales = ({ item, editItem, validation, habilidades }) => {

    const agregarHabilidades = (id) => {
        const interesesActuales = validation.values?.acreditaciones?.habilidades || [];

        let nuevosIntereses = [];
        if (interesesActuales.filter(interes => interes.id == id).length > 0) {
            nuevosIntereses = interesesActuales.filter(interes => interes.id !== id)
            validation.setFieldValue('acreditaciones.habilidades', nuevosIntereses);
        } else {
            const interesesActualesCategory = interesesActuales.filter(intereses => intereses.id == id)

            if (interesesActualesCategory.length < 5) {
                nuevosIntereses = [...interesesActuales, { id: id }];
                validation.setFieldValue('acreditaciones.habilidades', nuevosIntereses);
            } else {
                dangerToast('Solo puedes seleccionar maximo 5 intereses personales');
            }
        }
    }

    return (
        <>
            <div className="flex flex-row flex-wrap gap-2">
                <div className="flex flex-row items-center w-full pl-6 space-x-2">
                    <p className="font-bold text-sm whitespace-nowrap">Habilidades profesionales</p>
                    <hr className="flex-1 ml-2" />
                </div>
                {editItem ?
                    <>
                        <div className="flex flex-wrap justify-start items-center mt-3 sm:mt-6 gap-2 sm:gap-3">
                            {habilidades.map((data, index) => {
                                return (
                                    <button
                                        type="button"
                                        key={index}
                                        className={`justify-center px-2.5 py-1 whitespace-nowrap rounded-md border border-solid border-stone-100 hover:bg-o2o-gris-calido ${validation?.values?.acreditaciones?.habilidades.length > 0 ? (validation?.values?.acreditaciones?.habilidades?.filter(res => res?.id === data?.id).length > 0 ? 'bg-o2o-gris-calido-hover' : '') : ''}`}
                                        onClick={() => agregarHabilidades(data?.id)}
                                    >
                                        {data.nombre}
                                    </button>
                                )
                            })}
                        </div>
                    </> :
                    <>
                        {
                            item?.acreditaciones?.habilidades?.map((habilidades, index) => (
                                <span
                                    key={index}
                                    className="flex items-center bg-[#E5E5E5] px-4 rounded-full border border-black h-6"
                                >
                                    {habilidades.nombre}
                                </span>
                            ))}
                    </>}

            </div>
        </>
    );
};

export default HabilidadesProfesionales;
