import React, { useEffect, useRef, useState } from "react";
import { FaTrash, FaFileUpload } from "react-icons/fa";
import CustomButton from "../../form/CustomButton";
import CustomInput from "../../form/CustomInput";
import FileInput from "../../form/FileInput"

const ExperienciaLabEdit = ({ item, validation, editItem, onDelete }) => {
    const fileInputRefs = useRef([]);
    const addExperienciaLaboral = () => {
        const newObjeto = { id: (`random_${validation?.values?.experiencias_laborales.length + 1}`), cargo: '', actividades: '', fecha_inicio: '', fecha_inicio: '',comprobante:'' }
        validation.setFieldValue('experiencias_laborales', [...validation.values.experiencias_laborales, newObjeto])
    }
    return (
        <>
            <div className="flex flex-col">
                <div className="flex flex-row items-center w-full pl-6 space-x-2">
                    <p className="font-bold text-sm whitespace-nowrap">Experiencia laboral</p>
                    <hr className="flex-1 ml-2" />
                </div>
                {validation?.values?.experiencias_laborales?.map((e, i) => (
                    <div key={i} className="flex flex-row justify-between pl-6 mt-4">
                        {/* Periodo */}
                        <div className="flex flex-col flex-1">
                            <CustomInput
                                name={`experiencias_laborales[${i}].fecha_inicio`}
                                value={
                                    e.fecha_inicio || ""
                                }
                                onChange={validation.handleChange}
                                error={validation.errors?.experiencias_laborales?.[i]?.fecha_inicio}
                                placeholder="Fecha inicio"

                                type="date"
                                className="text-sm mb-0"
                            />
                            <p className="text-xs">Fecha inicial</p>
                            <CustomInput
                                name={`experiencias_laborales[${i}].fecha_fin`}
                                value={
                                   e.fecha_fin || ""
                                }
                                onChange={validation.handleChange}
                                error={validation.errors?.experiencias_laborales?.[i]?.fecha_fin}
                                placeholder="Fecha fin"
                                type="date"
                                className="text-sm"
                            />
                            <p className="text-xs">Fecha final</p>
                        </div>

                        {/* Cargo */}
                        <div className="flex flex-col flex-1">
                            <CustomInput
                                name={`experiencias_laborales[${i}].cargo`}
                                value={
                                    e.cargo || ""
                                }
                                onChange={validation.handleChange}
                                placeholder="Cargo"
                                type="text"
                                error={validation.errors?.experiencias_laborales?.[i]?.cargo}
                                className="text-sm"
                            />
                            <p className="text-xs">Cargo</p>
                        </div>

                        {/* Actividades */}
                        <div className="flex flex-col flex-1">
                            <CustomInput
                                name={`experiencias_laborales[${i}].actividades`}
                                value={
                                    e.actividades || ""
                                }
                                onChange={validation.handleChange}
                                placeholder="Actividades"
                                error={validation.errors?.experiencias_laborales?.[i]?.actividades}
                                type="text"
                                className="text-sm"
                            />
                            <p className="text-xs">Actividades</p>
                        </div>

                        {/* Íconos de agregar/ver documento y eliminar */}
                        <div className="flex flex-col flex-1 justify-center items-center">
                            <div className="flex items-center space-x-4">
                                {/* Ícono para seleccionar archivo */}
                                <FileInput
                                    format={'pdf'}
                                    validation={validation}
                                    name={`experiencias_laborales[${i}].comprobante`}
                                    nameInput={'Subir archivo'}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="flex flex-wrap max-w-[90%] ml-[1%] mt-[10px] max-md:max-w-full max-md:ml-0  font-bold">
                <div className='flex flex-row cursor-pointer w-52' onClick={() => addExperienciaLaboral()}>
                    <span className="material-symbols-outlined my-auto">
                        add_circle
                    </span>
                    <p className='my-auto ml-2 font-bold'>Agregar otra especialidad</p>
                </div>
            </div>
        </>
    );
};

export default ExperienciaLabEdit;
