import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { pacienteResource } from "../../helpers/configRoutes/backend_helper";
import { useNavigate, useParams } from "react-router-dom";
import Administrador from "../clientes/Administrador";
import GeneralInfo from "../../components/CommonEnfermeroAdministrador/GeneralInfo";
import DireccionData from "../../components/CommonEnfermeroAdministrador/DireccionData";
import CustomButton from "../../components/form/CustomButton";



const PacienteDetalle = () => {
    const [pacienteId, setPaciente] = useState({});
    const navigate = useNavigate();
    const { paciente } = useSelector((state) => state.PacientesState);
    const { paciente_id, id } = useParams();
    console.log(paciente)

    useEffect(() => {
        const getPaciente = async () => {
            const response = await pacienteResource('show', {}, paciente_id);
            setPaciente(response.paciente)
        }
        if (paciente.id == null) {
            getPaciente();
        } else {
            setPaciente(paciente)
        }

    }, []);

    const formatFecha = (fecha) => {
        if (!fecha) return '';
        const opciones = { day: 'numeric', month: 'long', year: 'numeric' };
        return new Date(fecha).toLocaleDateString('es-ES', opciones);
    };

    return (
        <>
            {pacienteId.id &&
                <Administrador>
                    <div className="tab-content tab-content-active space-y-8 bg-white p-6 rounded-md shadow-md">
                        <div className="flex items-center space-x-2">
                            <button
                                onClick={() => navigate(`/cliente/${id}/2`)}
                                className="text-blue-500 font-semibold">
                                &lt;
                            </button>
                            <h1 className="font-semibold text-lg">Pacientes</h1>
                        </div>
                        <div>
                            <GeneralInfo item={pacienteId} />
                        </div>
                        <div>
                            <DireccionData item={pacienteId} />
                        </div>
                        <div className='flex flex-row items-center w-full space-x-2'>
                            <p className='font-bold text-xs text-nowrap'>¿Padece alguna patología?</p>
                            <hr className='w-full px-2' />
                        </div>
                        <div className="flex">
                            <div className="flex flex-col space-y-2 mb-4 justify-center">
                                <div className="flex space-x-2 mb-4 justify-center">

                                    <div
                                        className={`flex items-center px-3 py-1 rounded-full border text-base font-bold ${!!pacienteId.patologia
                                            ? "bg-[#464646] text-white border-transparent"
                                            : "bg-white text-black border-black"
                                            }`}
                                    >
                                        sí
                                    </div>
                                    <div
                                        className={`flex items-center px-3 py-1 rounded-full border text-base font-bold ${pacienteId.patologia === false
                                            ? "bg-[#464646] text-white border-transparent"
                                            : "bg-white text-black border-black"
                                            }`}
                                    >
                                        no
                                    </div>

                                </div>

                                <CustomButton
                                    className="bg-black hover:bg-slate-600 text-white text-sm text-center px-1 rounded"
                                >
                                    Ver Médico responsable
                                </CustomButton>
                            </div>
                            <div className="flex-1">
                                <div className="flex flex-row items-center w-full pl-6 space-x-2">
                                    <p className="font-bold text-sm whitespace-nowrap">diagnóstico médico</p>
                                </div>
                                <div className='flex flex-row justify-between'>
                                    <div className='flex flex-col flex-1'>
                                        <p className='font-medium text-lg'>{pacienteId.diagnostico}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-1">
                                <div className="flex flex-row items-center w-full pl-6 space-x-2">
                                    <p className="font-bold text-sm whitespace-nowrap">condiciones especiales</p>
                                </div>
                                <div className='flex flex-row justify-between'>
                                    <div className='flex flex-col flex-1'>
                                        <p className='font-medium text-lg'>{pacienteId.condiciones_especiales}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-row items-center w-full space-x-2'>
                            <p className='font-bold text-xs text-nowrap'>Requiere un enfermero especialista en: </p>
                            <hr className='w-full px-2' />
                        </div>
                        <div className="grid grid-cols-4 gap-4">
                            <div className="p-4">
                                <div className='flex flex-col flex-1'>
                                    <p className='font-medium text-lg'>{pacienteId.especialidad_nombre}</p>
                                    <p className='text-sm'>Especialidad</p>
                                </div>
                            </div>
                            <div className="p-4">
                                <div className='flex flex-col flex-1'>
                                    <p className='font-medium text-lg'>cuidados que busca:</p>
                                    {pacienteId?.cuidados.map((cuidados, index) => (
                                        <span
                                            key={index}
                                            className="flex items-center bg-[#E5E5E5] px-3 py-1 rounded-full border border-gray-400 text-sm mb-1"
                                        >
                                            {cuidados.nombre}
                                        </span>
                                    ))}

                                </div>
                            </div>
                            <div className="p-4">
                                <div className="flex flex-col flex-1">
                                    <p className="font-medium text-lg">
                                        Actualmente lo atiende:
                                    </p>
                                    <div className="relative mt-2">
                                        <img
                                            src={pacienteId?.enfermero_asignado?.enfermero?.perfilImg}
                                            alt="nurse img"
                                            className="object-fill h-8 w-8 rounded-full"
                                        />
                                        <p className="text-sm absolute top-2 left-10 z-10 bg-white p-1 rounded-md">
                                            {pacienteId?.enfermero_asignado?.enfermero?.nombre} {pacienteId?.enfermero_asignado?.enfermero?.apellidos}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="p-4">
                                <CustomButton
                                    className="bg-black hover:bg-slate-600 text-white text-sm text-center px-1 rounded"
                                >
                                    Ver Enfermero
                                </CustomButton>
                            </div>
                        </div>
                        <div className='flex flex-row items-center w-full space-x-2'>
                            <p className='font-bold text-xs text-nowrap'>Requiere un enfermero especialista en: </p>
                            <hr className='w-full px-2' />
                        </div>
                        <div className="grid grid-cols-4 gap-4">
                            <div className="p-4">
                                <div className='flex flex-col flex-1'>
                                    <p className='font-medium text-lg'>{pacienteId?.necesidad?.dias.dia}</p>
                                    <p className='text-sm'>Los días</p>
                                </div>
                            </div>
                            <div className="p-4">
                                <div className='flex flex-col flex-1'>
                                    <p className='font-medium text-lg'>{formatFecha(pacienteId?.fecha_inicio)}</p>
                                    <p className='text-sm'>Inicia</p>
                                </div>
                            </div>
                            <div className="p-4">
                                <div className='flex flex-col flex-1'>
                                    <p className='font-medium text-lg'>{formatFecha(pacienteId?.fecha_fin)}</p>
                                    <p className='text-sm'>Termina</p>
                                </div>
                            </div>
                            <div className="p-4">
                                <div className='flex flex-col flex-1'>
                                    <p className='font-medium text-lg'>{pacienteId?.turno_id}</p>
                                    <p className='text-sm'>Turno</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Administrador>
            }
        </>
    )
}

export default PacienteDetalle;