import React, { useEffect, useState } from "react";
import GenericCard from "../../../components/cards/GenericCard";
import CustomSearchInput from "../../../components/form/CustomSearchInput";
import CustomSelect from "../../../components/form/CustomSelect";
import DataTableElement from "../../../components/elements/DataTableElement";
import { enfermeroResource, getEspecialidades } from "../../../helpers/configRoutes/backend_helper";
import Columns from "../Columns";
import PreviewPDF from "../../../components/modal/PreviewPdf";
import { infoToastCenter } from "../../../helpers/Toast";
import RazonBloqueoModal from "./RazonBloqueoModal";

const Bloqueados = () => {
    const [enfermeros, setEnfermeros] = useState([]);
    const [especialidades, setEspecialidades] = useState([]);
    const [tableLoad, setTableLoad] = useState({ show: false, textLoad: 'Loading enfermero...' });
    const [totalEnfermeros, setTotalEnfermeros] = useState(0);
    const [enfermerosExistencias, setEnfermerosExistencias] = useState(0)
    const [modalPdf, setModalPdf] = useState({ show: false, url: '' });
    const [modal, setModal] = useState({ show: false, item:''});
    const [filters, setFilters] = useState({ search: '', orderBy: '',calificacion:'',especialidad_id:'',bloqueados:true });
    const handleChange = ({ name, value }) => {
        setFilters({ ...filters, [name]: value });
    }
    const sortList = [
        { id: 1, name: 'A-Z', value: 'asc' },
        { id: 2, name: 'Z-A', value: 'desc' },
    ]

    useEffect(() => {
        const getTotals = async () => {
            const response = await enfermeroResource('get', {params:{bloqueados:true}});
            const enfermeros = Array.isArray(response.enfermeros) ? response.enfermeros : [];
            setTotalEnfermeros(enfermeros.length);
            const count = response.enfermerosExistencias?.filter(enfermero => enfermero.solicitud?.estatus_id === 4).length || 0;
            setEnfermerosExistencias(response.enfermerosExistencias || [])
            const responseEspecialidades = await  getEspecialidades()
            setEspecialidades(responseEspecialidades.especialidades)
        };
        getTotals();
    }, []);

    useEffect(() => {
        const getEnfermero = async () => {
            const response = await enfermeroResource('get', {params:filters});
            const enfermeros = Array.isArray(response.enfermeros) ? response.enfermeros : [];
            setEnfermeros(enfermeros);
        };
        getEnfermero();
    }, [filters]);

    const showMotivos = (data) =>{
        setModal({show:true,item:data,title:`Razones del bloqueo de cuenta de ${data.nombre} ${data.apellidos}`})
    }

    const cols = Columns(setModalPdf,showMotivos);

    return (
        <>
            <div className="flex flex-col w-full p-4">
                <div className="flex flex-row">
                    <p className="font-bold text-lg">Enfermeros</p>
                </div>
                <div className="flex flex-row justify-between w-full">
                    <div className="flex flex-row w-1/2">
                        <div className="w-1/3">
                            <GenericCard name="Total de Enfermeros" text={totalEnfermeros.toString()} />
                        </div>
                    </div>

                    <div className="flex flex-col justify-between w-1/2">
                        <div className="flex flex-row w-full h-10 space-x-2"></div>
                        <div className="flex flex-row">
                            <CustomSearchInput handleChange={(e) => setFilters({ ...filters, search: e.target.value })} value={filters.search} />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-full rounded shadow-xl border mt-4">
                    <div className="flex flex-row justify-between p-4">
                        <div className="flex flex-row w-3/4 items-center space-x-2">
                            <p className="">Ordenar por</p>
                            <div className="w-40">
                                <CustomSelect
                                    className={'border px-4 py-2 rounded'}
                                    options={[{ id: 1, nombre: 'A - Z' }, { id: 2, nombre: 'Z - A' }]}
                                    holder={'Alfabético'}
                                    value={filters.orderBy}
                                    handleChange={(e) => setFilters({ ...filters, orderBy: e.target.value })}
                                />
                            </div>
                            <div className="w-40">
                                <CustomSelect
                                    className={'border px-4 py-2 rounded'}
                                    options={especialidades}
                                    holder={'Especialidad'}
                                    value={filters.especialidad_id}
                                    handleChange={(e) => setFilters({ ...filters, especialidad_id: e.target.value })}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <DataTableElement columns={cols} tableLoad={tableLoad} items={enfermeros} />
            </div>
            {modal.show &&
                <RazonBloqueoModal modal={modal} setModal={setModal} />
            }
        </>
    );
}

export default Bloqueados;