import React, { useState } from "react";
import CustomInput from "../../form/CustomInput";
import CustomSelect from "../../form/CustomSelect";
import CustomButton from "../../form/CustomButton";
import FileInput from "../../form/FileInput";
import { FaEye, FaFileUpload } from "react-icons/fa";
import PreviewPDF from '../../modal/PreviewPdf'

const FormacionProfesional = ({ item, validation, editItem, estudios }) => {
    const [modalPdf,setModalPdf] = useState({show:false,url:''});
    return (
        <>
            <div className="flex flex-col">
                <div className="flex flex-row justify-between">
                    <div className="flex flex-col flex-1 pl-6">
                        {editItem ? (
                            <>
                                <CustomSelect
                                    name="estudios"
                                    options={estudios}
                                    value={validation.values?.estudios}
                                    error={validation.errors?.estudios}
                                    onChange={validation.handleChange}
                                />
                            </>
                        ) : (
                            <p className="font-medium text-base">{item?.acreditaciones?.estudios_nombre}</p>
                        )}
                        <p className="text-xs">Estudios</p>
                    </div>
                    <div className="flex flex-col flex-1 pl-6">

                        {editItem ? (
                            <CustomInput
                                name="casa_estudios"
                                value={validation.values?.casa_estudios || ""}
                                error={validation.errors?.casa_estudios}
                                placeholder="Casa de estudios"
                                type="text"
                                onChange={validation.handleChange}
                                className="font-medium text-base"
                            />
                        ) : (
                            <p className="font-medium text-base">{item?.acreditaciones.casa_estudios}</p>
                        )}
                        <p className="text-xs">Casa de estudios</p>
                    </div>
                    <div className="flex flex-col flex-1 pl-6">
                        {editItem ? (
                            <>
                                <div className="grid grid-cols-1 gap-4">
                                    <CustomInput
                                        name="cedula_profesional"
                                        value={validation.values?.cedula_profesional || ""}
                                        error={validation.errors?.cedula_profesional}
                                        placeholder="Cédula profesional"
                                        type="text"
                                        onChange={validation.handleChange}
                                        className="font-medium text-base"
                                    />
                                    <p className="text-xs">Cédula profesional</p>
                                    <div className="flex items-center space-x-4">
                                        <FileInput
                                            format={'pdf'}
                                            name={`cedula_doc`}
                                            validation={validation}
                                            nameInput={'Subir cédula '}
                                        />
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="grid grid-cols-1 gap-4">
                                    <p className="font-medium text-base">{item?.acreditaciones?.cedula_profesional}</p>
                                    {item.acreditaciones.cedula_doc &&
                                        <div className="flex flex-col flex-1  items-right ">
                                            <CustomButton onClick={()=>setModalPdf({firma:true,show:true,url:item.acreditaciones.cedula_doc})}>
                                                Ver Archivo
                                            </CustomButton>
                                        </div>
                                    }
                                    <p className="text-xs">Cédula profesional</p>
                                </div>
                            </>

                        )}


                    </div>
                    <div className="flex flex-col flex-1 pl-6">

                        {editItem ? (
                            <div>

                                <div className="flex space-x-4">
                                    {/* Fecha de inicio */}
                                    <div>
                                        <CustomInput
                                            name="graduacion_fecha"
                                            value={validation.values?.graduacion_fecha || ""}
                                            error={validation.errors?.graduacion_fecha}
                                            placeholder="Cédula profesional"
                                            type="text"
                                            onChange={validation.handleChange}
                                            className="font-medium text-base"
                                        />
                                    </div>
                                </div>
                            </div>

                        ) : (
                            <p className="font-medium text-base">{item?.acreditaciones?.graduacion_fecha}</p>
                        )}
                        <p className="text-[12px]">Graduación Fecha</p>
                    </div>
                </div>
                <div className="flex flex-row items-center w-full pl-6 mt-[23px]">
                    <p className="font-bold text-xs whitespace-nowrap">Descripción profesional</p>
                    <hr className="flex-1 ml-2" />
                </div>
                <div className="flex flex-row justify-between">
                    <div className="flex flex-col flex-1 pl-6">
                        {editItem ? (
                            <CustomInput
                                name="descripcion_profesional"
                                value={validation.values?.descripcion_profesional || ""}
                                error={validation.errors?.descripcion_profesional}
                                placeholder="Descripción profesional"
                                type="text"
                                isTextArea={true}
                                onChange={validation.handleChange}
                                className="text-sm border rounded-md"
                                style={{ width: "670px", height: "87px" }}
                            />
                        ) : (
                            <p className="text-sm">{item?.acreditaciones?.descripcion_profesional}</p>
                        )}
                    </div>
                </div>
            <PreviewPDF modal={modalPdf} setModal={setModalPdf}/>
            </div>
        </>
    );
};

export default FormacionProfesional;