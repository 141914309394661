import React from "react";
import CustomInput from "../../components/form/CustomInput";

const Descripcion = ({ item, validation, editItem }) => {
    return (
        <>
            <div className="flex flex-row space-x-2 mt-10 mb-10">
                <div className="flex flex-col w-1/2">
                    <div className="flex flex-row items-center w-full space-x-2">
                        <p className="font-bold text-xs text-nowrap">Descripción personal</p>
                        <hr className="w-full px-2" />
                    </div>
                    {editItem ? (
                        <CustomInput
                            name="descripcionPersonal"
                            value={validation.values.descripcionPersonal || ""}
                            error={validation.errors?.descripcionPersonal}
                            placeholder="Descripción personal"
                            type="text"
                            isTextArea={true}
                            onChange={validation.handleChange}
                            className="font-normal text-lg"
                            style={{ width: "405px", height: "81px" }}
                        />
                    ) : (
                        <p className="font-normal text-lg">{item?.descripcionPersonal}</p>
                    )}
                </div>
                <div className="flex flex-col w-1/2">
                    <div className="flex flex-row items-center w-full space-x-2">
                        <p className="font-bold text-xs text-nowrap">Información que lo hace único</p>
                        <hr className="w-full px-2" />
                    </div>
                    {editItem ? (
                        <CustomInput
                            name="informacionUnica"
                            value={validation.values.informacionUnica || ""}
                            error={validation.errors?.informacionUnica}
                            placeholder="Información única"
                            type="text"
                            isTextArea={true}
                            onChange={validation.handleChange}
                            className="font-normal text-lg"
                            style={{ width: "405px", height: "81px" }}
                        />
                    ) : (
                        <p className="font-normal text-lg">{item?.informacionUnica}</p>
                    )}
                </div>
            </div>
        </>
    );
};

export default Descripcion;
