import React from "react";
import CustomInput from "../../form/CustomInput";
import { formatCurrencyUsd } from "../../../helpers/Formatos";

const TurnosEnfermero = ({ item, editItem, validation }) => {

    return (
        <>
            <div className="flex flex-col w-full px-6 space-y-4">
                {/* Header Section */}
                <div className="flex flex-row items-center w-full space-x-2">
                    <p className="font-bold text-sm whitespace-nowrap">Disponibilidad</p>
                    <hr className="flex-1 w-full" />
                </div>

                {/* Content Section */}
                <div className="w-full overflow-x-auto">
                    <table className="table-auto w-full border-collapse border border-gray-300">
                        {/* Encabezados de la tabla */}
                        <thead>
                            <tr className="bg-gray-200 text-left">
                                <th className="border border-gray-300 px-4 py-2">Días</th>
                                <th className="border border-gray-300 px-4 py-2">Turno</th>
                                <th className="border border-gray-300 px-4 py-2">Tiempo</th>
                                <th className="border border-gray-300 px-4 py-2">Costo</th>
                            </tr>
                        </thead>
                        {/* Filas de datos */}
                        <tbody>
                            {item?.turnos?.map((m, index) => (
                                <tr key={index} className="hover:bg-gray-100">
                                    <td className="border border-gray-300 px-4 py-2">{m?.diasCollect.map((d, i) => (
                                        <span key={i}> {d.dia} </span>
                                    ))}</td>
                                    <td className="border border-gray-300 px-4 py-2">{m?.turno?.nombre || "N/A"}</td>
                                    <td className="border border-gray-300 px-4 py-2">{m?.tiempo?.nombre || "N/A"}</td>
                                    <td className="border border-gray-300 px-4 py-2">{formatCurrencyUsd(m?.nivelCosto) || "N/A"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </div>
        </>
    );
};

export default TurnosEnfermero;
