import React, { useEffect, useState } from "react";
import GenericCard from "../../components/cards/GenericCard";
import { Link, useNavigate } from "react-router-dom";
import CustomSearchInput from "../../components/form/CustomSearchInput";
import CustomSelect from "../../components/form/CustomSelect";
import CustomButton from "../../components/form/CustomButton";
import { clientsResource, pacienteResource } from "../../helpers/configRoutes/backend_helper";

const Clientes = () => {
    const [clientes, setClientes] = useState([]);
    const navigate = useNavigate();
    const [totalClientes, setTotalClientes] = useState(0);
    const [filters, setFilters] = useState({ search: '', orderBy:'' })

    useEffect(() => {
        const getTotals = async () => {
            const totals = await clientsResource('get', { params: { totals: true } });
            setTotalClientes(totals);
        };
        getTotals();
    }, []);

    useEffect(() => {
        const getClientes = async () => {
            const response = await clientsResource('get', { params: filters });
            const clientes = Array.isArray(response.clientes) ? response.clientes : [];
            setClientes(clientes);
        };
        getClientes();
    }, [filters]);

    return (
        <>
            <div className="flex flex-col w-full p-4">
                <div className="flex flex-row">
                    <p className="font-bold text-lg">Clientes / Pacientes</p>
                </div>
                <div className="flex flex-row justify-between w-full">
                    <div className="flex flex-row w-1/2">
                        <div className="w-1/3">
                            <GenericCard name="Total de usuarios" text={totalClientes.total} />
                        </div>
                        <div className="w-1/3 mx-2">
                            <GenericCard name="Usuarios activos" text={totalClientes.activos} />
                        </div>
                        <div className="w-1/3 mx-2">
                            <GenericCard name="Nuevos registros" text={totalClientes.nuevos} />
                        </div>
                    </div>

                    <div className="flex flex-row justify-end items-center w-1/2">
                        <CustomSearchInput handleChange={(e) => setFilters({ ...filters, search: e.target.value })} value={filters.search} />
                    </div>
                </div>
                <div className="flex flex-col w-full rounded shadow-xl border mt-2">
                    <div className="flex flex-row justify-between p-2">
                        <div className="flex items-center justify-between mb-2">
                            <div className="flex space-x-4">
                                <CustomSelect
                                    class={'border px-4 py-2 rounded'}
                                    options={[{ id: 1, nombre: 'A - Z' }, { id: 2, nombre: 'Z - A' }]}
                                    holder={'Ordenar por'}
                                    value={filters.orderBy}
                                    handleChange={(e) => setFilters({...filters,orderBy:e.target.value})}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-row w-full flex-wrap p-4'>
                    {clientes && clientes.length > 0 ? (
                        clientes.map((item, index) => (
                            <div className="flex w-1/5 items-center select-none" key={item.id || index}>
                                <div className="flex flex-col w-full bg-gray-200 m-2 p-2 rounded relative">
                                    <div className="flex flex-row w-full">
                                        <div className="flex w-1/3">
                                            <img
                                                src={item?.imagen || "https://static.vecteezy.com/system/resources/previews/001/886/209/non_2x/doctor-medical-cartoon-design-vector.jpg"}
                                                alt="nurse img"
                                                className="object-fill h-12 rounded-full mx-auto"
                                            />
                                        </div>
                                        <div className="flex flex-col w-2/3">
                                            <p className="font-bold text-base">{item?.nombre}</p>
                                            <p className="text-xs">Nombre</p>
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <p className="font-bold text-base">{item?.apellidos}</p>
                                        <p className="text-xs">Apellidos</p>
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <p className="font-bold text-base">{item?.pacientes.length}</p>
                                        <p className="text-xs">Pacientes registrados</p>
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <p className="font-bold text-base">{item?.servicios.length}</p>
                                        <p className="text-xs">Servicios solicitados</p>
                                    </div>
                                    <div className="flex flex-col w-full pt-2">
                                        <CustomButton
                                            onClick={() => navigate(`/cliente/${item?.id}`)}
                                            className="bg-black hover:bg-slate-600 mx-2 text-white text-center px-1 rounded"
                                        >
                                            Ver usuario
                                        </CustomButton>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>No clients found</p>
                    )}

                </div>
            </div>
        </>
    );
}

export default Clientes;