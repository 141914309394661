import React, { useEffect, useState } from "react";
import BaseModalAnimated from "../../../components/modal/BaseModalAnimated";
import CustomButton from "../../../components/form/CustomButton";
import { formatCurrencyUsd } from "../../../helpers/Formatos";
import { getComision,confirmarPago } from "../../../helpers/configRoutes/backend_helper";

const ConfirmarPagoModal = ({ modal, setModal,filters, setFilters }) => {
    const [loading, setLoading] = useState(false);
    const [comision, setComision] = useState(0)

    useEffect(() => {
        const getData = async () => {
            setLoading({ status: true, text: 'Cargando' })
            const response = await getComision({ params: { transaccion: 'payment' } }, modal.item.stripe_id);
            if (response.status) {
                setComision(response.commission)

                setLoading({ status: false, text: 'Cargando' })
            }
            setLoading({ status: false, text: 'Cargando' })
        };
        if (modal.item.stripe_id) {
            getData();
        }
    }, []);

    const save = async() => {
        setLoading({status:true, text:'Confirmando Pago'})
        const response = await confirmarPago(modal.item)
        if(response.status){
            setLoading({status:false, text:'Confirmando Pago'})
            setFilters({...filters,tab:filters.tab})
            setModal({...modal,show:false,item:{}})
        }
        setLoading({status:false, text:'Confirmando Pago'})
    }

    return (
        <BaseModalAnimated setModal={setModal} modal={modal}>
            <div className="grid">
                <p>Detalles</p>
                <div className="pl-3 w-full mx-auto">
                    <div className="flex justify-between font-bold mb-4">
                        <span>ID Transacción {modal.item.stripe_id}</span>
                        <span>Fecha y hora {modal.item.created_at}</span>
                    </div>
                    {/* Detalles */}
                    <div className="space-y-2">
                        <div className="flex justify-between">
                            <span>+ Total del servicio</span>
                            {console.log(comision, 'copmsi')}
                            <span className="font-semibold">
                                {(loading.status && loading.text == 'Cargando') ?
                                    ` ${formatCurrencyUsd(0)}`
                                    :
                                    ` ${formatCurrencyUsd(modal.item.cantidad)}`
                                }
                            </span>
                        </div>
                        <div className="flex justify-between">
                            <span>- Comisiones de transacción</span>
                            <span className="font-semibold">
                                {(loading.status && loading.text == 'Cargando') ?
                                    ` ${formatCurrencyUsd(0)}`
                                    :
                                    ` ${formatCurrencyUsd(comision)}`
                                }
                            </span>
                        </div>
                    </div>

                    {/* Monto final */}
                    <div className="text-center font-bold text-lg mt-4">
                        Monto final a recibir
                        <span className="text-black">
                            {(loading.status && loading.text == 'Cargando') ?
                                ` ${formatCurrencyUsd(0)}`
                                :
                                ` ${formatCurrencyUsd(modal.item.cantidad - parseFloat(comision))}`
                            }
                        </span>
                    </div>
                </div>
            </div>

            {/* Pie del modal */}
            <div className="flex items-center justify-center p-6 rounded-b border-slate-200">
                <CustomButton loading={loading.status} textLoad={loading.text} className={"bg-black text-white w-full py-3 rounded text-lg hover:bg-gray-800"} onClick={() => setModal({ show: false })}>
                    Cancelar
                </CustomButton>
                <CustomButton loading={loading.status} textLoad={loading.text} className={"bg-black text-white w-full py-3 rounded text-lg hover:bg-gray-800"} onClick={()=>save()}>
                    Aceptar
                </CustomButton>
            </div>
        </BaseModalAnimated>
    )
}

export default ConfirmarPagoModal