import React, { useEffect, useState } from "react";
import BaseModalAnimated from "../../../components/modal/BaseModalAnimated";
import CustomButton from "../../../components/form/CustomButton";
import { getFactura } from "../../../helpers/configRoutes/backend_helper";

const FacturaVisualizarModal = ({ modal, setModal, filters, setFilters }) => {
    const [loading, setLoading] = useState(false);
    const [fileURL, setFileURL] = useState(null);
    
    useEffect(() => {
        const handleSignedURL = async () => {
          if (modal.firma) {
            try {
              const response = await fetch(modal.url);
              if (!response.ok) {
                throw new Error("Failed to fetch the file.");
              }
              const blob = await response.blob();
              const objectURL = URL.createObjectURL(blob);
              setFileURL(objectURL);
            } catch (error) {
              console.error("Error fetching the signed URL:", error);
              setFileURL(null);
            }
          } else {
            // If not a signed URL, use the direct URL
            setFileURL(modal.url);
          }
        };
    
        if (modal.url) {
          handleSignedURL();
        }
    
        // Cleanup object URL when the component unmounts or modal.url changes
        return () => {
          if (fileURL) {
            URL.revokeObjectURL(fileURL);
          }
        };
      }, [modal.url, modal.firma]);

    const sendEmail = async() => {
        setLoading(true)
        const response = await getFactura({pago_id:modal.item.id_data,email:true})
        if(response.status){
            setLoading(false)
        }
        setLoading(false)
    }

    return (
        <BaseModalAnimated setModal={setModal} modal={modal}>
            <div className="grid">
                <div className="pl-3 w-full mx-auto">
                  {modal.item.type==2 &&
                    <div className="flex justify-between  mb-4 text-base w-1/2">
                        <CustomButton loading={loading} textLoad={'Enviando'} className={"bg-black text-white w-full py-3 rounded text-lg hover:bg-gray-800"} onClick={() => sendEmail()}>
                            Enviar por correo electónico
                        </CustomButton>
                    </div>
                    }
                </div>
            </div>
            <div className="flex flex-col items-center justify-center p-6 rounded-b border-slate-200">
                {/* Previsualización del archivo */}
                {fileURL ? (
                    <iframe
                        src={fileURL}
                        allowFullScreen
                        style={{
                            overflow: "auto",
                            height: "500px",
                            width: "100%",
                        }}
                    ></iframe>
                ) : (
                    <p>Loading preview...</p>
                )}
            </div>

            {/* Pie del modal */}
            <div className="flex items-center justify-center p-6 rounded-b border-slate-200">
                <CustomButton loading={loading} textLoad={'Enviando'} className={"bg-black text-white w-full py-3 rounded text-lg hover:bg-gray-800"} onClick={() => setModal({ show: false })}>
                    Aceptar
                </CustomButton>
            </div>
        </BaseModalAnimated>
    )
}

export default FacturaVisualizarModal