import React, { useEffect, useState } from "react";
import { enfermeroResource, getAuthUser, usersResource, usuariosResource } from "../../helpers/configRoutes/backend_helper";
import FirstTab from "../../components/CommonEnfermeroAdministrador/tabs/FirstTab";
import CustomTabs from "../../components/dashboard/nurces/tab/CustomTabs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import EstudiosAcreditaciones from "../enfermeros/EstudiosAcreditaciones";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomButton from "../../components/form/CustomButton";
import TrabajoOne2One from "../enfermeros/TrabajoOne2One";
import DeclinarModal from "../pendingRequest/DeclinarModal";
import { showFormErrors } from "../../helpers/showFormErrors";
import RazonBloqueoModal from "../enfermeros/Bloqueados/RazonBloqueoModal";

const Persona = () => {
    const location = useLocation();
    const [saveEstatus, setSaveEstatus] = useState(false)
    const [editItemSave, setEditItemSave] = useState(false)
    const { id } = useParams();
    const navigate = useNavigate();
    const [item, setItem] = useState({});
    const title = location.pathname.startsWith('/enfermero') ? "Enfermero" : "Cliente";
    const [editItem, setEditItem] = useState(false)
    const [tabs, setTabs] = useState([]);
    const [modal, setModal] = useState({ show: false, title: 'Bloqueo de cuenta', model: 'SolicitudTrabajo' })
    const [modalRazones,setModalRazones] = useState({ show: false})

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: item,
        validationSchema: Yup.object({}),
        onSubmit: async (values, { setErrors }) => {
            try {
                // Aquí iría tu lógica para guardar los cambios en la DB
                const response = await usuariosResource('put', values, values.id)
                if (response.status) {
                    setSaveEstatus(false)
                    setEditItem(false); // Finaliza el modo edición
                    setEditItemSave(!editItemSave)
                }
            } catch (error) {
                console.error('Error al guardar:', error);
            }
        },
    });

    useEffect(() => {
        const getItem = async () => {
            try {
                let response = { status: false };

                if (location.pathname.startsWith('/enfermero')) {
                    response = await enfermeroResource('show', {}, id);
                    setItem(response.enfermero);
                    validation.setValues(response.enfermero);
                    setTabs([
                        { id: 1, label: 'Información general', content: <FirstTab setEditItem={setEditItem} editItem={editItem} setItem={setItem} enfermeroData={response.enfermero} validationItem={validation} setSaveEstatus={setSaveEstatus} /> },
                        { id: 2, label: 'Estudios y acreditaciones', content: <EstudiosAcreditaciones setEditItem={setEditItem} editItem={editItem} item={response.enfermero} setItem={setItem} validationItem={validation} setSaveEstatus={setSaveEstatus} /> },
                        { id: 3, label: 'Trabajo en One2One', content: <TrabajoOne2One setEditItem={setEditItem} editItem={editItem} item={response.enfermero} setItem={setItem} validationItem={validation} setSaveEstatus={setSaveEstatus} /> },
                    ])
                }
            } catch (error) {
                console.error('Error al obtener los datos:', error);
            }
        };

        if (id) {
            getItem();
        }
    }, [id, location.pathname, editItemSave, saveEstatus]);

    useEffect(() => {
        if (item.id) {
            setTabs([
                { id: 1, label: 'Información general', content: <FirstTab setEditItem={setEditItem} editItem={editItem} setItem={setItem} enfermeroData={item} validationItem={validation} setSaveEstatus={setSaveEstatus} /> },
                { id: 2, label: 'Estudios y acreditaciones', content: <EstudiosAcreditaciones setEditItem={setEditItem} editItem={editItem} item={item} setItem={setItem} validationItem={validation} setSaveEstatus={setSaveEstatus} /> },
                { id: 3, label: 'Trabajo en One2One', content: <TrabajoOne2One setEditItem={setEditItem} editItem={editItem} item={item} setItem={setItem} validationItem={validation} setSaveEstatus={setSaveEstatus} /> },
            ]);
        }
    }, [editItem]);

    const authUser = getAuthUser();

    return (
        <>
            <div className="flex justify-between items-center">
                <div className="flex items-center space-x-2">
                    <button
                        onClick={() => navigate('/enfermeros')}
                        className="text-blue-500 font-semibold">
                        &lt;
                    </button>
                    <h1 className="font-semibold text-lg">{title}</h1>
                </div>
                <div className="flex flex-col space-y-2">
                    {editItem ? (
                        <>


                        </>
                    ) : (
                        <>
                            <CustomButton
                                className="bg-black text-white font-semibold py-2 px-4 rounded mt-2"
                                onClick={() => setEditItem(true)}>
                                Editar cuenta
                            </CustomButton>
                            {item.deleted_at ?
                                <>
                                    <CustomButton
                                        className="bg-gray-400 text-white font-semibold py-2 px-4 rounded"
                                        onClick={() => setModal({ ...modal, show: true,reactivar:true,model:'SolicitudTrabajoAceptacion' })}>
                                            Reactivar Cuenta
                                    </CustomButton>
                                    <CustomButton
                                        className="bg-gray-400 text-white font-semibold py-2 px-4 rounded"
                                        onClick={() => setModalRazones({show:true,item:item,title:`Razones del bloqueo de cuenta de ${item.nombre} ${item.apellidos}`})}>
                                            Ver razón de bloqueo
                                    </CustomButton>
                                </>
                                :
                                <CustomButton
                                    className="bg-gray-400 text-white font-semibold py-2 px-4 rounded"
                                    onClick={() => setModal({ ...modal, show: true })}>
                                    Bloquear cuenta
                                </CustomButton>
                            }

                        </>
                    )}
                </div>
            </div>
            <CustomTabs tabs={tabs} />
            {(validation.values.id && modal.show) &&
                <DeclinarModal validation={validation} modal={modal} setModal={setModal} />
            }
            {modalRazones.show &&
                <RazonBloqueoModal modal={modalRazones} setModal={setModalRazones} />
            }

        </>
    );
};

export default Persona;
