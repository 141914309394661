
export default function GenericCard ({name, text, subtext }) {
    return (
        <div className="bg-white shadow rounded-lg p-4 text-center mt-4">   
            <div className="flex flex-col rounded shadow-xl p-4 w-full">
                <p className="text-sm font-normal text-o2o-gray-title">
                    {name}
                </p>
                <p className="text-4xl font-norma">{text}</p>
                <div className={`text-xs text-gray-500 ${!subtext ? 'invisible' : ''}`}>
                    {subtext || 'placeholder'}
                </div>
            </div>
        </div>
    )
}