import React from "react";
import GeneralInfo from "../../components/CommonEnfermeroAdministrador/GeneralInfo";
import DireccionData from "../../components/CommonEnfermeroAdministrador/DireccionData";
import Descripcion from "../../components/CommonEnfermeroAdministrador/Descripcion";

const ClienteID = ({ item }) => {
    return (
        <>
            <div className="tab-content tab-content-active space-y-8 bg-white p-6 rounded-md shadow-md">
                <div>
                    <GeneralInfo item={item}/>
                </div>
                <div>
                    <DireccionData item={item} />
                </div>
                <div className='flex flex-row items-center w-full space-x-2'>
                    <p className='font-bold text-xs text-nowrap'>Datos de facturación</p>
                    <hr className='w-full px-2' />
                </div>
                <div className='flex flex-row justify-between'>
                    <div className='flex flex-col flex-1'>
                        <p className='font-medium text-lg'>{item?.bancarios?.rfc || 'No registrado'}</p>
                        <p className='text-sm'>RFC</p>
                    </div>
                    <div className='flex flex-col flex-1'>
                        <p className='font-medium text-lg'>{item?.bancarios?.razon_social || 'No registrado'}</p>
                        <p className='text-sm'>Régimen Fiscal </p>
                    </div>
                    <div className='flex flex-col flex-1'>
                        <p className='font-medium text-lg'>{item?.bancarios?.nombre || 'No registrado'}</p>
                        <p className='text-sm'>CFDI</p>
                    </div>
                </div>
                <div>
                    <Descripcion item={item} />
                </div>
                <div className="flex flex-col w-full space-y-2">
                    <div className="flex flex-row items-center w-full pl-6 space-x-2">
                        <p className="font-bold text-sm whitespace-nowrap">Intereses personales</p>
                        <hr className="flex-1 ml-2 border-gray-400" />
                    </div>
                    <div className="flex flex-wrap gap-2 px-6">
                        {item?.interesesPersonales.map((interes, index) => (
                            <span
                                key={index}
                                className="flex items-center bg-[#E5E5E5] px-3 py-1 rounded-full border border-gray-400 text-sm"
                            >
                                {interes.nombre}
                            </span>
                        ))}
                    </div>
                </div>


            </div>
        </>
    )
}
export default ClienteID;