import React, { useEffect, useState } from "react";
import BaseModalAnimated from "../../../components/modal/BaseModalAnimated";
import { facturasEnfermeroResource, serviciosResource } from "../../../helpers/configRoutes/backend_helper";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/form/CustomButton";
import { formatCurrencyUsd } from "../../../helpers/Formatos";
import PreviewPDF from "../../../components/modal/PreviewPdf"

const FacturaCitasModal = ({ modal, setModal,facturasData }) => {
    const navigate = useNavigate()
    const [facturas, setFacturas] = useState([])
    const [modalDoc, setModalDoc] = useState({ show: false, url: '' })
    useEffect(() => {
        const getFacturas = async () => {
            const response = await facturasEnfermeroResource('get', { params: { servicio_id: modal.id } });
            setFacturas(response.facturas);
        };
        if (modal.id) {
            getFacturas();
        }else{
            setFacturas(facturasData)
        }

    }, [modal.id]);

    return (
        <BaseModalAnimated setModal={setModal} modal={modal}>
            {/* Lista de facturas */}
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white w-3/4 max-h-[90vh] rounded-lg shadow-lg overflow-y-auto">
                    {/* Encabezado del modal */}
                    <div className="p-4 border-b border-gray-300">
                        <h2 className="text-lg font-bold">Facturas</h2>
                    </div>

                    {/* Contenido del modal */}
                    <div className="p-4">
                        <div className="grid grid-cols-3 gap-4">
                            {facturas.length > 0 ? (
                                facturas.map((f, index) => (
                                    <div
                                        className="flex flex-col bg-gray-200 m-2 p-4 rounded min-h-[200px] w-full"
                                        key={index}
                                    >
                                        <div className="flex flex-col w-full h-full">
                                            <p className="font-bold text-base">
                                                {f?.estatus_nombre}
                                            </p>
                                            <p className="text-xs">Estatus</p>
                                            <p className="font-bold text-base">
                                                {formatCurrencyUsd(f?.cantidad)}
                                            </p>
                                            <p className="text-xs">Monto</p>
                                            <div className="flex-grow">
                                                {f.citas?.map((c, ci) => (
                                                    <div key={ci}>
                                                        <span>
                                                            Turno número: {c.cita.cita_no}{" "}
                                                            Fecha: {c.cita.fecha_inicial}
                                                        </span>
                                                        <p className="text-xs">
                                                            Turno facturado
                                                        </p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="flex justify-between mt-2">
                                            <CustomButton
                                                onClick={() =>
                                                    setModalDoc({
                                                        show: true,
                                                        url: f.factura_pdf,
                                                        firma: true,
                                                    })
                                                }
                                                className="bg-black hover:bg-slate-600 text-white text-center px-3 py-1 rounded text-xs"
                                            >
                                                Ver PDF
                                            </CustomButton>
                                            <CustomButton
                                                onClick={() =>
                                                    setModalDoc({
                                                        show: true,
                                                        url: f.factura_xml,
                                                        firma: false,
                                                    })
                                                }
                                                className="bg-black hover:bg-slate-600 text-white text-center px-3 py-1 rounded text-xs"
                                            >
                                                Descargar XML
                                            </CustomButton>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className="text-center w-full">No hay datos disponibles.</p>
                            )}
                        </div>
                    </div>

                    {/* Pie del modal */}
                    <div className="flex items-center justify-center p-6 border-t border-gray-300">
                        <button
                            className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded"
                            onClick={() => setModal({ show: false })}
                        >
                            Cerrar
                        </button>
                    </div>
                </div>
            </div>
            <PreviewPDF modal={modalDoc} setModal={setModalDoc} />
        </BaseModalAnimated>
    )
}

export default FacturaCitasModal