import React, { useEffect, useState } from "react";
import BaseModalAnimated from "../../../components/modal/BaseModalAnimated";
import { useNavigate } from "react-router-dom";
import StarRating from "../../../components/starRating/StarRating";
import { formatCurrencyUsd } from "../../../helpers/Formatos";
import CustomButton from "../../../components/form/CustomButton";
import { dispersionFacturasEnfermero } from "../../../helpers/configRoutes/backend_helper";

const PagoModal = ({ modal, setModal, filters, setFilters, facturas, setFacturas }) => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const pagar = async () => {
        setLoading(true)
        const data = { factura_id: modal.data.id }
        const response = await dispersionFacturasEnfermero(data);
        if (response.status) {
            setFilters({ ...filters })
            setFacturas({ ...facturas })
            setLoading(false)
            setModal({ show: false })
        }
        setLoading(false)
    }

    return (
        <BaseModalAnimated setModal={setModal} modal={modal}>
            <div className="p-4">

                <h2 className="text-center text-[22px]"></h2>
                <p className="text-[22px]"></p>
            </div>
            {/* Lista de motivos */}
            <div className="grid">
                <div className="p-6 space-y-4">
                    {/* Cliente y servicios */}
                    <div className="flex justify-between items-start">
                        <div className="w-12 h-12 flex-shrink-0">
                            <img
                                src={modal.data?.servicio?.administrador.perfilImg || '/public/images/usrAnonimo.png'}
                                alt="Perfil"
                                className="w-full h-full rounded-full object-cover"
                            />
                        </div>
                        <div>
                            <p className="text-sm font-bold">Cliente y servicios que solicitó</p>
                            <p className="text-lg font-medium">{modal.data?.servicio?.administrador.nombre} {modal.data?.servicio?.administrador.apellidos}</p>
                            <div className="flex space-x-2 mt-2">
                                {modal.data.servicio?.paciente.cuidados.map((c, i) => (
                                    <span key={i} className="bg-gray-100 text-sm px-2 py-1 rounded">{c.nombre}</span>
                                ))}
                            </div>
                        </div>
                        <div className="text-sm">
                            <p className="font-bold">Período de servicio</p>
                            <ul className="list-disc list-inside">
                                <li>{modal.data?.servicio?.fecha_inicial} a {modal.data?.servicio?.fecha_fin}</li>
                            </ul>
                        </div>
                    </div>

                    {/* Enfermero */}
                    <div className="border-t pt-4">
                        <div className="flex items-center space-x-4">
                            {/* Imagen del perfil */}
                            <div className="w-12 h-12 flex-shrink-0">
                                <img
                                    src={modal.data?.servicio?.enfermero.perfilImg || '/public/images/usrAnonimo.png'}
                                    alt="Perfil"
                                    className="w-full h-full rounded-full object-cover"
                                />
                            </div>

                            {/* Información del enfermero */}
                            <div>
                                <p className="text-sm font-bold">Enfermero</p>
                                <p className="text-lg font-medium">{modal.data?.servicio?.enfermero.nombre} {modal.data?.servicio?.enfermero.apellidos}</p>
                                <span className="bg-blue-100 text-blue-600 text-xs px-2 py-1 rounded">{modal.data?.servicio?.enfermero.especialidad}</span>
                            </div>
                        </div>

                        <div className="flex mt-4 space-x-4">
                            <div>
                                <p className="font-medium">{modal.data?.servicio?.enfermero.movil}</p>
                                <p className="text-xs text-gray-500">móvil</p>
                            </div>
                            <div>
                                <p className="font-medium">{modal.data?.servicio?.enfermero.email}</p>
                                <p className="text-xs text-gray-500">mail</p>
                            </div>
                        </div>
                    </div>

                    {/* Detalles */}
                    <div className="border-t pt-4">
                        <p className="text-sm font-bold mb-2">Detalles</p>
                        <div className="flex items-center mb-4">
                            <StarRating rating={modal.data?.servicio?.enfermero.calificacion} />
                        </div>
                        <div className="text-sm">
                            {modal.data?.citas.map((c, i) => (
                                <p className="flex justify-between" key={i}>
                                    <span>+ Total turno facturado. Turno: #{c.cita.cita_no}</span>
                                    <span>{formatCurrencyUsd(c.cantidad)}</span>
                                </p>
                            ))

                            }
                        </div>
                        <p className="flex justify-between font-bold mt-4">
                            <span>Monto final a pagar</span>
                            <span>{formatCurrencyUsd(modal.data?.cantidad)}</span>
                        </p>
                    </div>
                    {/* Botón de acción */}
                </div>
            </div>

            {/* Pie del modal */}
            <div className="flex items-center justify-center p-6 rounded-b border-slate-200">
                {modal.data.estatus_id == 23 &&
                    <CustomButton loading={loading} textLoad={'Procesando dispersión'} className={"bg-black text-white w-full py-3 rounded text-lg hover:bg-gray-800"} onClick={() => pagar()}>
                        Realizar pago
                    </CustomButton>
                }
            </div>
        </BaseModalAnimated>
    )
}

export default PagoModal