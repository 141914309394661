import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/form/CustomButton";
import { formatCurrencyUsd } from "../../helpers/Formatos";
import { facturasEnfermeroResource, serviciosResource } from "../../helpers/configRoutes/backend_helper";
import { get } from "../../helpers/configRoutes/api_helper";

const Columns = (activeTab, setModal) => {

    const getDetalles = async (row) => {
        let data = {};
        let title = ''
        switch (row.type) {
            case 2:
                const servicio = await serviciosResource('show', {}, row.servicio_id)
                data = servicio.servicio
                title = 'Detalles del servicio '
                setModal({
                    show: true,
                    type: 2,
                    item: row,
                    data: data,
                    title: title
                })
                break;
            case 3:
                const response = await facturasEnfermeroResource('show', {}, row.id_data);
                setModal({
                    show: true,
                    type: 4,
                    item: row,
                    data: response.factura,
                    title: 'Detalles del pago de honorarios',
                    pago: false
                })
            case 4:
                const servicioResponse = await serviciosResource('show', {}, row.servicio_id)
                data = servicioResponse.servicio
                title = 'Detalles del Reembolso '
                setModal({
                    show: true,
                    type: 2,
                    item: row,
                    data: data,
                    title: title,
                    reembolso:true
                })
            default:
                return false
                break;
        }
    }

    const goPago = async (row) => {
        const response = await facturasEnfermeroResource('show', {}, row.id_data);
        const servicio = await serviciosResource('show', {}, response.factura.servicio_id)
        const data = servicio.servicio
        setModal({
            show: true,
            type: 4,
            item: row,
            data: response.factura,
            title: 'Pago de honorarios a enfermeros'
        })
    }

    let columns = [
        {
            id: 'estatus_id',
            name: 'Estado',
            selector: row => (
                <div className="flex items-center gap-4">
                    <CustomButton
                        className={`text-xs text-black`}
                        style={{ backgroundColor: row.estatus_color, color: 'black' }}
                        title={'Ver Usuario'}
                    >
                        {row.estatus_nombre}
                    </CustomButton>
                </div>

            )
        },
        {
            id: '_id',
            name: 'ID transacción',
            selector: row => `${row.stripe_id ?? row.id_data}`,
        },
        {
            id: 'created_at',
            name: 'Fecha y hora',
            selector: row => `${row.created_at}`,
        },
        {
            id: 'monto',
            name: 'Monto',
            selector: row => `${formatCurrencyUsd(row.cantidad)}`,
        },
        {
            id: 'destinatario',
            name: 'Destinatario',
            selector: row => `${row.destinatario}`,
        },
        {
            id: 'metodo_pago',
            name: 'Metodo de p.',
            selector: row => `${row.metodo_pago}`,
        },
        {
            id: 'acciones',
            name: 'Acciones',
            selector: row => (
                <div className="flex items-center gap-4">
                    {row.type != 4 &&
                        <>
                            <CustomButton
                                isDisabled={(row.type == 2 && !row.factura_pdf)}
                                onClick={() => setModal({ show: true, type: 3, title: 'Facturas', item: row, url: row.factura_pdf, firma: true })}
                                className={'bg-o2o-secondary hover:bg-o2o-secondary-hover text-xs'}
                                title={'Ver factura'}
                            >
                                Ver factura
                            </CustomButton>
                            {(row.type == 2 && !row.recompenza && row.estatus_id == 11) &&
                                <CustomButton
                                    isDisabled={row.estatus_id != 11 || row.recompenza}
                                    onClick={() => setModal({ show: true, type: 1, title: `¿Confirmas que recibiste el monto de la transacción ${row.stripe_id}?`, item: row })}
                                    className={'bg-o2o-secondary hover:bg-o2o-secondary-hover text-xs'}
                                    title={'Confirmar Pago de cliente'}
                                >
                                    Confirmar
                                </CustomButton>
                            }
                            {(row.type == 3 && row.estatus_id == 23) &&
                                <CustomButton
                                    onClick={() => goPago(row)}
                                    className={'bg-o2o-secondary hover:bg-o2o-secondary-hover text-xs'}
                                    title={'Realizar pargo a enfermero'}
                                >
                                    Pagar
                                </CustomButton>
                            }
                        </>
                    }
                    <CustomButton
                        onClick={() => getDetalles(row)}
                        className={'bg-o2o-secondary hover:bg-o2o-secondary-hover text-xs'}
                        title={'Ver detalles de transaccion'}
                    >
                        Ver detalles
                    </CustomButton>
                </div>
            ),
        }
    ]
    return columns;
}

export default Columns;