import React, { useEffect, useState } from "react";
import CustomInput from "../form/CustomInput";
import { getCps, municipios, paises, estados } from '../../helpers/configRoutes/backend_helper'
import CustomSelect from "../form/CustomSelect";

const DireccionData = ({ item, validation, editItem }) => {

    const [c_municipios, setCMunicipios] = useState([]);
    const [c_paises, setCpaises] = useState([])
    const [c_estados, setCEstados] = useState([])
    const [cps, setCps] = useState([])
    const [showOptions, setShowOptions] = useState(false)

    useEffect(() => {
        const getPaises = async () => {
            const response = await paises();
            const responseEstados = await estados();
            setCpaises(response.paises)
            setCEstados(responseEstados.estados)
        }
        getPaises()
    }, []);

    useEffect(() => {
        const getMunicipios = async () => {
            const response = await municipios({ params: { estado_id: validation.values.estado } });
            setCMunicipios(response.municipios)
        }
        if(editItem){
            getMunicipios()
        }
        
    }, [validation?.values?.estado]);

    const loadOptions = async (input) => {
        validation.setFieldValue('cp_id', null)
        if (input.length > 3) {
            setShowOptions(true)
            const options = await getCps({ params: { search: input } });
            setCps(options.cps);
        } else {
            setShowOptions(false)
        }
    };

    const setOptionCp = (option) => {
        validation.setFieldValue('cp', `${option.cp} - ${option.asentamiento}`)
        validation.setFieldValue('cp_id', option.id)
        setShowOptions(false)
        validation.setFieldValue('estado', option.estado_id)
        validation.setFieldValue('pais', 1)
        validation.setFieldValue('municipio', option.municipio_id)
        validation.setFieldValue('colonia', option.asentamiento)
    }
    return (
        <>
            <div className="flex flex-col">
                {editItem ? (
                    <>
                        <div className='flex flex-row items-center w-full space-x-2'>
                            <p className='font-bold text-xs text-nowrap'>Dirección</p>
                            <hr className='w-full px-2' />
                        </div>
                        <div className='flex flex-row justify-between'>
                            <div className='flex flex-col flex-1'>
                                <CustomInput
                                    name="calle"
                                    value={validation.values.calle || ""}
                                    error={validation.errors?.calle}
                                    placeholder="Calle"
                                    type="text"
                                    onChange={validation.handleChange}
                                    className="font-medium text-lg"
                                />
                                <p className='text-sm'>Calle</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <CustomInput
                                    name="numeroExterior"
                                    value={validation.values.numeroExterior || ""}
                                    error={validation.errors?.numeroExterior}
                                    placeholder="Número exterior"
                                    type="text"
                                    onChange={validation.handleChange}
                                    className="font-medium text-lg"
                                />
                                <p className='text-sm'>Número exterior</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <CustomInput
                                    name="numeroInterior"
                                    value={validation.values.numeroInterior || ""}
                                    error={validation.errors?.numeroInterior}
                                    placeholder="Número interior"
                                    type="text"
                                    onChange={validation.handleChange}
                                    className="font-medium text-lg"
                                />
                                <p className='text-sm'>Número interior</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <CustomInput
                                    name="colonia"
                                    value={validation.values.colonia || ""}
                                    error={validation.errors?.colonia}
                                    placeholder="Colonia"
                                    type="text"
                                    onChange={validation.handleChange}
                                    className="font-medium text-lg"
                                />
                                <p className='text-sm'>Colonia</p>
                            </div>
                        </div>
                        <div className='flex flex-row justify-between'>
                            <div className='flex flex-col flex-1'>
                                <div className="justify-center ">
                                    <CustomInput
                                        name="cp"
                                        value={validation.values.cp || ''}
                                        error={validation.errors?.cp}
                                        placeholder={'Código postal'}
                                        type={'text'}
                                        onChange={(e) => [validation.setFieldValue('cp', e.target.value), loadOptions(e.target.value)]}
                                        maxLength="5"
                                    />
                                    {showOptions && (
                                        <div className="absolute mt-1 w-[560px] border border-gray-300 rounded-lg shadow-lg bg-white z-10 max-h-40 overflow-y-auto">
                                            {cps.length > 0 ? (
                                                cps.map(option => (
                                                    <div
                                                        key={option.id}
                                                        onClick={() => setOptionCp(option)}
                                                        className="p-2 cursor-pointer hover:bg-gray-100"
                                                    >
                                                        {option.cp} - {option.asentamiento}
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="p-2 text-gray-500">No options found</div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <p className='text-sm'>Código postal</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <CustomSelect
                                    name="municipio"
                                    disabled={true}
                                    options={c_municipios}
                                    value={validation.values.municipio || ''}
                                    error={validation.errors?.municipio}
                                    onChange={validation.handleChange}
                                />
                                <p className='text-sm'>Municipio</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <CustomSelect
                                    name="municipio"
                                    disabled={true}
                                    options={c_estados}
                                    value={validation.values.estado || ''}
                                    error={validation.errors?.estado}
                                    onChange={validation.handleChange}
                                />
                                <p className='text-sm'>Estado</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <CustomSelect
                                    name="municipio"
                                    disabled={true}
                                    options={c_paises}
                                    value={validation.values.pais || ''}
                                    error={validation.errors?.pais}
                                    onChange={validation.handleChange}
                                />
                                <p className='text-sm'>País</p>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className='flex flex-row items-center w-full space-x-2'>
                            <p className='font-bold text-xs text-nowrap'>Dirección</p>
                            <hr className='w-full px-2' />
                        </div>
                        <div className='flex flex-row justify-between'>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>{item?.calle || 'No registrado'}</p>
                                <p className='text-sm'>Calle</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>{item?.numeroExterior || 'No registrado'}</p>
                                <p className='text-sm'>Número exterior</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>{item?.numeroInterior || 'No registrado'}</p>
                                <p className='text-sm'>Número interior</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>{item?.colonia}</p>
                                <p className='text-sm'>Colonia</p>
                            </div>
                        </div>
                        <div className='flex flex-row justify-between'>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>{item?.cp}</p>
                                <p className='text-sm'>Código postal</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>{item?.municipio_nombre}</p>
                                <p className='text-sm'>Municipio</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>{item?.estado_nombre}</p>
                                <p className='text-sm'>Estado</p>
                            </div>
                            <div className='flex flex-col flex-1'>
                                <p className='font-medium text-lg'>{item?.pais_nombre}</p>
                                <p className='text-sm'>País</p>
                            </div>
                        </div>
                    </>
                )}


            </div>
        </>
    )
}
export default DireccionData;