import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/login/Login";
import MiddlewareAuth from "./components/middleware/MiddlewareAuth";
import Loading from "./components/main/Loading";
import Toast from "./components/main/Toast";
import Home from "./pages/home/Home";
import { ToastContainer } from "react-toastify";
import ForgotPassword from "./pages/login/ForgotPassw";
import Enfermeros from "./pages/enfermeros/Enfermeros";
import Persona from "./pages/servicios/Persona";
import PendingReques from "./pages/pendingRequest/PendingRequest";
import AplicationInfo from "./pages/pendingRequest/AplicationInfo";
import EstudiosAcreditaciones from "./pages/enfermeros/EstudiosAcreditaciones";
import TrabajoOne2One from "./pages/enfermeros/TrabajoOne2One";
import Clientes from "./pages/clientes/Clientes";
import Administrador from "./pages/clientes/Administrador";
import PacienteDetalle from "./pages/pacientes/PacienteDetalle";
import Pacientes from "./pages/pacientes/Pacientes";
import Banco from "./pages/banco/Banco";
import HistorialServicios from "./pages/servicios/HistorialServicios";
import Bloqueados from "./pages/enfermeros/Bloqueados/Bloqueados";

const App = () => {
  return (
    <>
      <Toast />
      <ToastContainer />
      <Loading />
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route
            path="/"
            element={
              <MiddlewareAuth>
                <Home />
              </MiddlewareAuth>
            }
          />
          <Route
            path="/enfermeros"
            element={
              <MiddlewareAuth>
                <Enfermeros />
              </MiddlewareAuth>
            }
          />
          <Route
            path="/enfermero/:id"
            element={
              <MiddlewareAuth>
                <Persona />
              </MiddlewareAuth>
            }
          />
          <Route
            path="/enfermero-acreditciones/:id"
            element={
              <MiddlewareAuth>
                <EstudiosAcreditaciones />
              </MiddlewareAuth>
            }
          />
          <Route
            path="/enfermero-trabajo/:id"
            element={
              <MiddlewareAuth>
                <TrabajoOne2One />
              </MiddlewareAuth>
            }
          />
          <Route
            path="/enfermeros-bloqueados"
            element={
              <MiddlewareAuth>
                <Bloqueados />
              </MiddlewareAuth>
            }
          />
          <Route
            path="/registros-pendientes"
            element={
              <MiddlewareAuth>
                <PendingReques />
              </MiddlewareAuth>
            }
          />
          <Route
            path="/registros-pendientes/:id"
            element={
              <MiddlewareAuth>
                <AplicationInfo />
              </MiddlewareAuth>
            }
          />
          <Route
            path="/clientes"
            element={
              <MiddlewareAuth>
                <Clientes />
              </MiddlewareAuth>
            }
          />
          <Route
            path="/cliente/:id/:tab?"
            element={
              <MiddlewareAuth>
                <Administrador />
              </MiddlewareAuth>
            }
          />

          <Route
            path="/paciente/:id/:paciente_id"
            element={
              <MiddlewareAuth>
                <PacienteDetalle />
              </MiddlewareAuth>
            }
          />


          <Route
            path="/banca"
            element={
              <MiddlewareAuth>
                <Banco />
              </MiddlewareAuth>
            }
          />
          <Route
            path="/historial-servicios"
            element={
              <MiddlewareAuth>
                <HistorialServicios />
              </MiddlewareAuth>
            }
          />

          <Route
            path="/loader"
            element={
              <MiddlewareAuth>
                <div
                  className={`transition duration-700 ease-in-out  w-full h-screen fixed left-20 top-20 z-10 justify-center items-center bg-black bg-opacity-70 ${"flex"}`}
                >
                  En construccion...{" "}
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
              </MiddlewareAuth>
            }
          />
        </Routes>
      </Router>
    </>
  );
};

export default App;
