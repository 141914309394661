import React, { useState } from "react";
import { enfermeroResource } from "../../helpers/configRoutes/backend_helper";
import StarRating from "../../components/starRating/StarRating";
import { useNavigate } from "react-router-dom";
import { Tab } from "@mui/material";
import CustomButton from "../../components/form/CustomButton";

const Columns = (setModalPdf, bloqueados) => {
    const navigate = useNavigate();
    const [load, setLoad] = useState(false)
    const getDetalleUsuario = async (id) => {
        setLoad(true)
        const response = await enfermeroResource('show', { params: { report: 'pdf' } }, id)
        setLoad(false)
        console.log(response.pdf_base64)
        setModalPdf({ show: true, url: response.pdf_base64 })
    }

    let columns = [
        {
            id: 'photo',
            name: '',
            selector: row => <img
                src={row.perfilImg ? row.perfilImg : '/public/images/usrAnonimo.png'}
                alt="Perfil"
                style={{ width: '30px', height: '30px', borderRadius: '50%' }}
            />
        },
        {
            id: 'nombre',
            name: 'Nombre',
            selector: row => `${row.nombre} ${row.apellidos}`,
        },
        {
            id: 'especialidad',
            name: 'Especialidad',
            selector: row => (
                <>
                    {row.acreditaciones?.formacion_academica.map((esp, i) => (
                        <span key={i}>{esp.especialidad_nombre}</span>
                    ))}
                </>
            ),
        },
        {
            id: 'nivel',
            name: 'Nivel',
            selector: row => `${row.nivel_nombre}`,
        },
        {
            id: 'disponibilidad',
            name: 'Disponibilidad',
            selector: row => (
                <>
                    {row.turnos.map((t, index) => (
                        <div className="flex flex-row space-x-4" key={index}>
                            {/* <div>
                                {t.diasCollect.map((d, index) => (
                                    <span key={d.id}>
                                        {d.dia + ', '}
                                    </span>
                                ))}
                            </div> */}
                            <div>
                                {t.turno.nombre} -  {t.tiempo.nombre}
                            </div>
                        </div>
                    ))}
                </>
            ),

        },
        {
            id: 'servicios',
            name: 'Servicios',
            selector: row => (
                <>
                    {row.enfermero?.servicios && row.enfermero.servicios.length > 0 ? (
                        <span>{row.enfermero.servicios.length}</span>
                    ) : (
                        <span>0</span>
                    )}
                </>
            ),
        },
        {
            id: 'calificacion',
            name: 'Calificación',
            selector: row => <StarRating rating={row.calificacion} />,
        },
        {
            id: 'acciones',
            name: 'Acciones',
            selector: row => (
                <div className="flex items-center gap-4">
                    {bloqueados ?
                        <CustomButton
                            onClick={() => bloqueados(row)}
                            className={'bg-o2o-secondary hover:bg-o2o-secondary-hover text-xs'}
                            title={'Descargar Detalle PDF'}
                            loading={load}
                            textLoad={'Generando Expediente'}
                        >
                            Ver razón
                        </CustomButton>
                        :
                        <CustomButton
                            onClick={() => getDetalleUsuario(row.id)}
                            className={'bg-o2o-secondary hover:bg-o2o-secondary-hover text-xs'}
                            title={'Descargar Detalle PDF'}
                            loading={load}
                            textLoad={'Generando Expediente'}
                        >
                            Expediente
                        </CustomButton>
                    }

                    <CustomButton
                        onClick={() => navigate(`/enfermero/${row.id}`)}
                        className={'bg-o2o-secondary hover:bg-o2o-secondary-hover text-xs'}
                        title={'Ver Usuario'}
                    >
                            Ver Usuario
                    </CustomButton>
                </div>
            ),
        }
    ]
    return columns;
}

export default Columns;