import React, { useEffect, useState } from 'react';
import { useFormik } from "formik";
import * as Yup from "yup";
import { validaCurp, validaDates, validaNames, validaNumerosTelefonos } from '../../../helpers/formularios/Validation';
import { showFormErrors } from "../../../helpers/showFormErrors";
import DireccionData from '../DireccionData';
import GeneralInfo from '../GeneralInfo';
import Descripcion from '../Descripcion';
import { getIntereses, usuariosResource } from '../../../helpers/configRoutes/backend_helper';
import { dangerToast } from '../../../helpers/Toast';
import CustomButton from '../../form/CustomButton';

const FirstTab = ({ enfermeroData, setSaveEstatus, validationItem, editItem, setEditItem }) => {
    const [estatusValidacion, setEstatusValidacion] = useState(2);
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: validationItem.values,
        validationSchema: Yup.object({
            nombre: validaNames(),
            apellidos: validaNames(),
            fechaNacimiento: validaDates(18),
            movil: validaNumerosTelefonos(true),
            telefono: validaNumerosTelefonos(false),
            email: Yup.string()
                .email('Correo electrónico inválido')
                .required('Campo requerido'),
            curp: validaCurp(),
            calle: Yup.string().required('Campo requerido'),
            cp: Yup.string().required('Campo requerido'),
            numeroExterior: Yup.string().required('Campo requerido'),
            numeroInterior: Yup.string().required('Campo requerido, En caso de no contar con Numero Interior poner lo mismo que numero exterior.'),
            pais: Yup.string().required('Campo requerido'),
            estado: Yup.string().required('Campo requerido'),
            municipio: Yup.string().required('Campo requerido'),
            colonia: Yup.string().required('Campo requerido'),
            descripcionPersonal: Yup.string(),
            informacionUnica: Yup.string(),
            perfilImg: Yup.string().required('Campo requerido'),
            sexo_id: Yup.string().required('Campo requerido'),
            identificacion:''
        }),
        onSubmit: async (values, { setErrors }) => {
            setEstatusValidacion(1)
            validationItem.setValues(values);
            // Aquí iría tu lógica para guardar los cambios en la DB
            const response = await usuariosResource('put', values, values.id)
            if (response.status) {
                setSaveEstatus(false)
                setEditItem(false); // Finaliza el modo edición
            }
            setEstatusValidacion(3);
            setSaveEstatus(true)
        },
    });

    const [categorias, setCategorias] = useState([])
    useEffect(() => {
        const getCatalogos = async () => {
            const response = await getIntereses(1)
            setCategorias(response.categorias)
        }
        if (editItem) {
            getCatalogos()
        } else {
            validation.resetForm()
        }
    }, [editItem]);

    useEffect(() => {
        if (estatusValidacion) {
            setEstatusValidacion(2)
            setSaveEstatus(false)
        }
    }, [validation.values]);

    const agregarInteresesPersonales = (id, categoria) => {
        const interesesActuales = validation.values.interesesPersonales || [];
        let nuevosIntereses = [];
        if (interesesActuales.filter(interes => interes.id == id).length > 0) {
            nuevosIntereses = interesesActuales.filter(interes => interes.id !== id)
            validation.setFieldValue('interesesPersonales', nuevosIntereses);
        } else {
            const interesesActualesCategory = interesesActuales.filter(intereses => intereses.categoria_id == categoria)
            if (interesesActualesCategory.length < 5) {
                nuevosIntereses = [...interesesActuales, { id: id, categoria_id: categoria }];
                validation.setFieldValue('interesesPersonales', nuevosIntereses);
            } else {
                dangerToast('Solo puedes seleccionar maximo 5 intereses personales');
            }
        }
    }

    if (!enfermeroData) {
        return <p>Cargando datos...</p>;
    }
    return (
        <>
            {editItem && (
                // Botón visible solo si editItem es true
                <form
                    onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        showFormErrors(validation);
                        return false;
                    }}
                >
                    <div className="w-full flex justify-end mt-[-50px]">
                        <CustomButton
                            className="bg-blue-500 text-white font-semibold py-2 px-4 rounded"
                            type={'submit'}
                        >
                            Guardar información
                        </CustomButton>
                    </div>
                </form>
            )}

            <div className='tab-content tab-content-active space-y-8 bg-white p-6 rounded-md shadow-md' id="enfermero-content-1">

                <GeneralInfo item={enfermeroData} validation={validation} editItem={editItem} />
                <div className='ml-10'>
                    <DireccionData item={enfermeroData} validation={validation} editItem={editItem} />
                    <Descripcion item={enfermeroData} validation={validation} editItem={editItem} />
                    <div className="flex flex-col">
                        <div className="flex flex-row items-center w-full space-x-2">
                            <p className="font-bold text-xs whitespace-nowrap">Intereses personales</p>
                            <div className="flex-grow">
                                <hr className="border-t border-gray-300 mt-1" />
                            </div>
                        </div>
                        <div className="flex flex-row space-x-2">
                            {editItem ?
                                <>
                                    {categorias.map((categoria, index) => (
                                        <div className="flex flex-col px-2 sm:px-4 pt-3 mt-3 w-full border-t border-black border-solid" key={index}>
                                            <div className="w-full font-bold">
                                                {categoria.nombre}
                                            </div>
                                            <div className="flex flex-wrap justify-start items-center mt-3 sm:mt-6 gap-2 sm:gap-3">
                                                {categoria.intereses.map((data, index) => {
                                                    return (
                                                        <button
                                                            type="button"
                                                            key={index}
                                                            className={`justify-center px-2 sm:px-2.5 py-1 whitespace-nowrap rounded-md border border-solid border-stone-100 hover:bg-o2o-gris-hover ${validation.values.interesesPersonales?.filter(res => res?.id === data?.id).length > 0 ? 'bg-o2o-gray-button' : ''}`}
                                                            onClick={() => agregarInteresesPersonales(data?.id, data.categoria_id)}
                                                        >
                                                            {data.nombre}
                                                        </button>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    ))}
                                </>
                                :
                                <>
                                    {enfermeroData.interesesPersonales && enfermeroData.interesesPersonales.map((interes, index) => (
                                        <span
                                            key={index}
                                            className="flex items-center bg-[#E5E5E5] px-4 rounded-full border border-black h-6"
                                        >
                                            {interes.nombre}
                                        </span>
                                    ))}
                                </>
                            }
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default FirstTab;
