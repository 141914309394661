import React, { useEffect, useState } from "react";
import GenericCard from "../../components/cards/GenericCard";
import { Link, useNavigate } from "react-router-dom";
import CustomSearchInput from "../../components/form/CustomSearchInput";
import CustomSelect from "../../components/form/CustomSelect";
import CustomButton from "../../components/form/CustomButton";
import DataTableElement from "../../components/elements/DataTableElement";
import { enfermeroResource, getEspecialidades } from "../../helpers/configRoutes/backend_helper";
import Columns from "./Columns";
import Filters from "./Filters";
import PreviewPDF from "../../components/modal/PreviewPdf";
import { infoToastCenter } from "../../helpers/Toast";

const Enfermeros = () => {
    const navigate = useNavigate();
    const [enfermeros, setEnfermeros] = useState([]);
    const [especialidades, setEspecialidades] = useState([]);
    const [tableLoad, setTableLoad] = useState({ show: false, textLoad: 'Loading enfermero...' });
    const [totalEnfermeros, setTotalEnfermeros] = useState(0);
    const [totalRegistrosN, setTotalRegistrosN] = useState(0);
    const [enfermerosExistencias, setEnfermerosExistencias] = useState(0)
    const [openModal, setOpenModal] = useState({ acceptAccount: false });
    const [modalPdf, setModalPdf] = useState({ show: false, url: '' });
    const [filters, setFilters] = useState({ id: 1, search: '', orderBy: '',calificacion:'',especialidad_id:'' });

    useEffect(() => {
        const getTotals = async () => {
            const response = await enfermeroResource('get', {});
            const enfermeros = Array.isArray(response.enfermeros) ? response.enfermeros : [];
            setTotalEnfermeros(enfermeros.length);
            const count = response.enfermerosExistencias?.filter(enfermero => enfermero.solicitud?.estatus_id === 4).length || 0;
            setTotalRegistrosN(count);
            setEnfermerosExistencias(response.enfermerosExistencias || [])
            if (count) {
                infoToastCenter('Tienes nuevas solicitudes de trabajo', 'bottom-right')
            }

            const responseEspecialidades = await  getEspecialidades()
            setEspecialidades(responseEspecialidades.especialidades)
        };
        getTotals();
    }, []);

    useEffect(() => {
        const getEnfermero = async () => {
            const response = await enfermeroResource('get', {params:filters});
            const enfermeros = Array.isArray(response.enfermeros) ? response.enfermeros : [];
            setEnfermeros(enfermeros);
        };
        getEnfermero();
    }, [filters]);

    
    const cols = Columns(setModalPdf);

    return (
        <>
            <div className="flex flex-col w-full p-4">
                <div className="flex flex-row">
                    <p className="font-bold text-lg">Enfermeros</p>
                </div>
                <div className="flex flex-row justify-between w-full">
                    <div className="flex flex-row w-1/2">
                        <div className="w-1/3">
                            <GenericCard name="Total de Enfermeros" text={totalEnfermeros.toString()} />
                        </div>
                        <div className="w-1/3 mx-2">
                            <GenericCard name="Nuevas solicitudes" text={totalRegistrosN.toString()} />
                        </div>
                        <div className="w-1/3 mx-2">
                            <GenericCard name="Total" text={enfermerosExistencias.length} />
                        </div>
                    </div>

                    <div className="flex flex-col justify-between w-1/2">
                        <div className="flex flex-row w-full h-10 space-x-2">
                            <Link
                                to="/registros-pendientes"
                                className="flex justify-center w-1/2 items-center rounded bg-o2o-gray-secondary hover:bg-o2o-gray-primary relative p-2"
                            >
                                {/* Texto Principal */}
                                <span className="text-black font-medium">Registros pendientes</span>

                                {/* Badge de Notificación */}
                                <div className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center text-xs font-bold shadow">
                                    {totalRegistrosN.toLocaleString()}
                                </div>
                            </Link>
                            <button className={`w-1/2 rounded bg-o2o-gray-secondary hover:bg-o2o-gray-primary`} onClick={()=>navigate('/enfermeros-bloqueados')}>
                                Cuentas bloqueadas
                            </button>
                        </div>
                        <div className="flex flex-row">
                            <CustomSearchInput handleChange={(e) => setFilters({ ...filters, search: e.target.value })} value={filters.search} />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-full rounded shadow-xl border mt-4">
                    <div className="flex flex-row justify-between p-4">
                        <div className="flex flex-row w-3/4 items-center space-x-2">
                            <p className="">Ordenar por</p>
                            <div className="w-40">
                                <CustomSelect
                                    className={'border px-4 py-2 rounded'}
                                    options={[{ id: 1, nombre: 'A - Z' }, { id: 2, nombre: 'Z - A' }]}
                                    holder={'Alfabético'}
                                    value={filters.orderBy}
                                    handleChange={(e) => setFilters({ ...filters, orderBy: e.target.value })}
                                />
                            </div>
                            <div className="w-40">
                                <CustomSelect
                                    className={'border px-4 py-2 rounded'}
                                    options={especialidades}
                                    holder={'Especialidad'}
                                    value={filters.especialidad_id}
                                    handleChange={(e) => setFilters({ ...filters, especialidad_id: e.target.value })}
                                />
                            </div>
                            <div className="w-40">
                            <CustomSelect
                                    className={'border px-4 py-2 rounded'}
                                    options={[{ id: 1, nombre: '1' }, { id: 2, nombre: '2' },{ id: 3, nombre: '3' },{ id: 4, nombre: '4' },{ id: 5, nombre: '5' }]}
                                    holder={'Nivel (Rewards)'}
                                    value={filters.calificacion}
                                    handleChange={(e) => setFilters({ ...filters, calificacion: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="flex flex-row w-1/4 items-center space-x-2">
                            <p>Filtros</p>
                            <CustomButton onClick={() => setOpenModal({ ...openModal, show: true })} className="tu-clase-adicional">
                                Personaliza tu búsqueda
                            </CustomButton>

                        </div>
                    </div>

                </div>
                <DataTableElement columns={cols} tableLoad={tableLoad} items={enfermeros} />
            </div>
            {openModal.show &&
                <Filters modal={openModal} setModal={setOpenModal} items={enfermeros} setItems={setEnfermeros} />
            }
            {modalPdf.show &&
                <PreviewPDF modal={modalPdf} setModal={setModalPdf} />
            }

        </>
    );
}

export default Enfermeros;