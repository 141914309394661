import React, { useEffect, useState } from "react";
import TurnosEnfermero from "../../components/CommonEnfermeroAdministrador/enfermero/TurnosEnfermero";
import CustomButton from "../../components/form/CustomButton";
import Servicios from "../../components/CommonEnfermeroAdministrador/enfermero/Servicios";
import { facturasEnfermeroResource, getPayouts } from "../../helpers/configRoutes/backend_helper";
import { formatCurrencyUsd } from "../../helpers/Formatos";
import PreviewPDF from "../../components/modal/PreviewPdf";
import PagoModal from "../banco/Enfermero/PagoModal";
import SingleCalendarDateRangePicker from "../../components/form/SingleCalendarDateRangePicker";
import CustomButtonOval from "../../components/form/CustomButtonOval";
import FacturaCitasModal from "./Servicios/FacturaCitasModal";

const TrabajoOne2One = ({ item, editItem, validation }) => {
    const [facturas, setFacturas] = useState(false)
    const [modalFacturas, setModalFacturas] = useState({show:false})
    
    const [facturasPagos, setFacturasPagos] = useState([])
    const [payouts, setPayouts] = useState({})
    const [showCalendar, setShowCalendar] = useState(false);
    const [modalDoc, setModalDoc] = useState({ show: false, url: '' })
    const [modalPago, setModalPago] = useState({ show: false, data: {} })
    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0]; // Formato YYYY-MM-DD
    };

    const getFirstDayOfMonth = () => {
        const today = new Date();
        const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
        return firstDay.toISOString().split('T')[0]; // Formato YYYY-MM-DD
    };

    const [filters, setFilters] = useState({
        perPage: 20,
        page: 1,
        fecha_inicio: getFirstDayOfMonth(),
        fecha_fin: getCurrentDate(),
        estatus_id:22,
        enfermero_id:item.id
    });

    const getFacturas = async () => {
        const response = await facturasEnfermeroResource('get', { params: { estatus_id: 23,enfermero_id:item.id } });
        setFacturasPagos(response.facturas);
    };

    const getPayoutsBanca = async () => {
        const response = await facturasEnfermeroResource('get', { params: filters });
        setPayouts(response);
    };

    useEffect(() => {
        getFacturas();
    }, [facturas]);

    useEffect(() => {
        getPayoutsBanca();
    }, [filters]);

    return (
        <>
            <div className="tab-content tab-content-active space-y-8 bg-white p-6 rounded-md shadow-md">
                <div>
                    <TurnosEnfermero item={item} validation={validation} editItem={editItem} />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 px-6">
                    {/* Primer Contenedor */}
                    <div className="flex flex-col space-y-4">
                        {/* Header Section */}
                        <div className="flex flex-row items-center w-full space-x-2">
                            <p className="font-bold text-sm whitespace-nowrap">Pagos</p>
                            <hr className="flex-1 w-full" />
                        </div>

                        {/* Content Section */}
                        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                            <div className="flex flex-col">
                                <p className="font-medium text-lg">{item.bancarios?.cuenta_no}</p>
                                <p className="text-sm">Número de cuenta</p>
                            </div>
                            <div className="flex flex-col">
                                <p className="font-medium text-lg">{item.bancarios?.entidad_nombre}</p>
                                <p className="text-sm">Entidad bancaria</p>
                            </div>
                            <div className="flex flex-col">
                                <p className="font-medium text-lg">{formatCurrencyUsd(payouts?.monto || 0)}</p>
                                <p className="text-sm">Facturado</p>
                            </div>
                            <div className="flex justify-center items-center">
                                <CustomButtonOval className="bg-black text-white font-semibold py-2 px-2 rounded w-full text-sm" onClick={() => setShowCalendar(!showCalendar)}>
                                    Seleccionar periodo
                                </CustomButtonOval>
                                <div className="relative">
                                    {!showCalendar ? (
                                        <div className="flex items-center space-x-2 border-r border-gray-100 pr-4" onClick={() => setShowCalendar(!showCalendar)}>
                                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                            </svg>
                                        </div>
                                    ) : (
                                        <div className="absolute top-full mt-2 left-0 z-50 bg-white shadow-lg p-4 border border-gray-200">
                                            <SingleCalendarDateRangePicker filters={filters} setFilters={setFilters} setShowCalendar={setShowCalendar} showCalendar={showCalendar} />
                                            <div className="mt-2">
                                                {filters.fecha_inicio && <p>Start Date: {new Date(filters.fecha_inicio).toLocaleDateString()}</p>}
                                                {filters.fecha_fin && <p>End Date: {new Date(filters.fecha_fin).toLocaleDateString()}</p>}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Segundo Contenedor */}
                    <div className="flex flex-col space-y-4">
                        {/* Header Section */}
                        <div className="flex flex-row items-center w-full space-x-2">
                            <p className="font-bold text-sm whitespace-nowrap">Historial de pagos</p>
                            <hr className="flex-1 w-full" />
                        </div>

                        {/* Content Section */}
                        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                            <div className="flex flex-col">
                                <p className="font-medium text-lg">{payouts?.totalFacturas}</p>
                                <p className="text-sm">Pagos en el periodo de {new Date(filters.fecha_inicio).toLocaleDateString()} a {new Date(filters.fecha_fin).toLocaleDateString()}</p>
                            </div>
                            <div className="flex justify-center items-center">
                                <CustomButtonOval className="bg-black text-white font-semibold py-2 px-2 rounded w-full text-xs" onClick={() => setModalFacturas({show:true})}>
                                    Ver todos
                                </CustomButtonOval>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="w-full">
                    <Servicios item={item} />
                    <div >
                        <div className="flex flex-row items-center w-full space-x-2 mb-4">
                            <p className="font-bold text-sm whitespace-nowrap">Pagos pendientes</p>

                            <hr className="flex-1 w-full" />
                        </div>
                        <div className="w-full">
                            {facturasPagos.map((item, index) => (
                                <div
                                    key={index}
                                    className="w-full bg-o2o-gray p-4 flex flex-row items-center justify-between mb-4"
                                >
                                    {/* Cliente */}
                                    <div className="flex flex-col">
                                        <p className="text-sm font-bold">{item.servicio?.enfermero?.nombre} {item.servicio?.enfermero.apellidos}</p>
                                        <p className="text-xs text-gray-600">Enfermero</p>
                                    </div>
                                    {/* Paciente */}
                                    <div className="flex flex-col">
                                        <p className="text-sm font-bold">{item.servicio?.paciente.nombre} {item.servicio?.paciente.apellidos}</p>
                                        <p className="text-xs text-gray-600">Paciente</p>
                                    </div>
                                    {/* Período del servicio */}
                                    <div className="flex flex-col">
                                        <p className="text-sm font-bold">{item.servicio?.fecha_inicial} a {item.servicio?.fecha_fin}</p>
                                        <p className="text-xs text-gray-600">Periodo del servicio</p>
                                    </div>
                                    {/* Pago del servicio */}
                                    <div className="flex flex-col">
                                        <p className="text-sm font-bold">{formatCurrencyUsd(item.cantidad)}</p>
                                        <p className="text-xs text-gray-600">Pago por citas</p>
                                    </div>
                                    {/* Botones */}
                                    <div className="flex flex-col space-y-2">
                                        <CustomButton className="bg-black text-white text-xs font-semibold py-1 px-3 rounded" onClick={() => setModalPago({ show: true, data: item, title: 'Pagar por servicio a enfermero' })}>
                                            Ingresar pago
                                        </CustomButton>
                                        <CustomButton className="bg-black text-white text-xs font-semibold py-1 px-3 rounded" onClick={() => setModalDoc({ show: true, title: 'Factura', url: item.factura_pdf, firma: true })}>
                                            Ver factura
                                        </CustomButton>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
                {modalDoc.show &&
                    <PreviewPDF modal={modalDoc} setModal={setModalDoc} />
                }
                {modalPago.show &&
                    <PagoModal modal={modalPago} setModal={setModalPago} filters={filters} setFilters={setFilters} facturas={facturas} setFacturas={setFacturas} />
                }
                {modalFacturas.show &&
                    <FacturaCitasModal modal={modalFacturas} setModal={setModalFacturas} facturasData={payouts.facturas}/>
                }
            </div>
        </>
    );
}

export default TrabajoOne2One;