import React, { useEffect, useState } from "react";
import CustomButton from "../../form/CustomButton";
import { serviciosResource } from "../../../helpers/configRoutes/backend_helper";
import { formatCurrencyUsd } from "../../../helpers/Formatos";
import ModalServicios from "../../../pages/enfermeros/Servicios/ModalServicios";

const Servicios = ({ item }) => {
    const [servicios, setServicios] = useState([])
    const [modal,setModal] = useState({show:false,title:'Historial de servicios',id:''})
    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0]; // Formato YYYY-MM-DD
    };
    
    const getFirstDayOfMonth = () => {
        const today = new Date();
        const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
        return firstDay.toISOString().split('T')[0]; // Formato YYYY-MM-DD
    };
    
    const [filters, setFilters] = useState({
        perPage: 5,
        page: 1,
        fecha_inicio: getFirstDayOfMonth(),
        fecha_fin: getCurrentDate(),
        enfermero_id:item.id
    });
    
    useEffect(() => {
        const getServicios = async () => {
            const response = await serviciosResource('get', { params: filters });
            setServicios(response.servicios);
        };
        if (item.id) {
            getServicios();
        }
    }, [item, filters]);

    return (
        <>
            <div className="grid grid-cols-[223px_auto] gap-6 items-start mb-2">
                {/* Primera Columna (Servicios) */}
                <div>
                    <div className="flex flex-row items-center w-full space-x-2 mb-4">
                        <p className="font-bold text-sm whitespace-nowrap">Servicios</p>
                        <hr className="flex-1 w-full" />
                    </div>
                    <div className="flex flex-col space-y-2">
                        <div className="flex flex-col">
                            <p className="text-sm font-bold">
                                {servicios?.filter(servicio => servicio.estatus_id === 5).length || 0}
                            </p>
                            <p className="text-sm">Solicitudes de servicio</p>
                        </div>
                        <div className="flex flex-col">
                            <p className="text-sm font-bold">
                                {servicios?.filter(servicio => servicio.estatus_id === 9).length || 0}
                            </p>
                            <p className="text-sm">Servicios en curso</p>
                        </div>
                        <div className="flex flex-col">
                            <p className="text-sm font-bold">
                                {servicios?.filter(servicio => servicio.estatus_id === 6).length || 0}
                            </p>
                            <p className="text-sm">Servicios completados</p>
                        </div>
                    </div>
                </div>

                {/* Segunda Columna (Historial mes) */}
                <div>
                    <div className="flex flex-row items-center w-full space-x-2 mb-4">
                        <p className="font-bold text-sm whitespace-nowrap">Historial mes</p>
                        <CustomButton
                            className="bg-black text-white font-semibold rounded w-1/7 h-[14px] text-xs flex items-center justify-center"
                            onClick={()=>setModal({...modal,show:true,id:item.id})}
                        >
                            Ver historial
                        </CustomButton>
                        <hr className="flex-1 w-full" />
                    </div>
                    <div className="grid grid-cols-3 gap-4">
                        {servicios.map((s, index) => (
                            <div
                                key={index}
                                className="w-[185px] h-[153px] bg-o2o-gray p-4 flex flex-col justify-between"
                            >
                                <div>
                                    <p className="text-sm font-bold">{s.paciente.nombre}</p>
                                    <p className="text-xs  font-bold">Cliente</p>
                                </div>
                                <div>
                                    <p className="text-sm font-bold">{s.fecha_inicial} a {s.fecha_fin}</p>
                                    <p className="text-xs font-bold">Periodo del servicio</p>
                                </div>
                                <div>
                                    <p className="text-sm font-bold">{formatCurrencyUsd(s.pagos.costo_total)}</p>
                                    <p className="text-xs  font-bold">Pago del servicio</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {(modal.show && modal.id) &&
                <ModalServicios modal={modal} setModal={setModal}/>
            }
            
        </>
    )
}

export default Servicios