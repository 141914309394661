import React, { useEffect, useState } from "react";
import GenericCard from "../../components/cards/GenericCard";
import { formatCurrencyUsd } from "../../helpers/Formatos";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getBancoDataTotals } from "../../helpers/configRoutes/backend_helper";

const HeadBanca = ({filtersDad,setFiltersDad}) => {
    const [data,setData] = useState({
        netos:0,
        facturado:0,
        recibidos:0,
        comisionesPagosRecibidos:0,
        realizados:0,
        comisionesPagosRealizados:0,
    })
    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0]; // Formato YYYY-MM-DD
    };

    const getFirstDayOfMonth = () => {
        const today = new Date();
        const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
        return firstDay.toISOString().split('T')[0]; // Formato YYYY-MM-DD
    };
    const [filters, setFilters] = useState({ fecha_inicio: getFirstDayOfMonth(), fecha_fin: getCurrentDate(), });

    useEffect(() => {
        const getBanca = async () => {
            const response = await getBancoDataTotals({params:filters});
            setData(response.data)
        };
        getBanca();
    }, [filters,filtersDad]);


    const handleDateChange = (date) => {
        if (!filters.fecha_inicio) {
            // If no start date is set, set the start date
            setFilters({ ...filters, fecha_inicio: date });
        } else if (!filters.fecha_fin && date > filters.fecha_inicio) {
            // If a start date is set and the selected date is after it, set it as the end date
            setFilters({ ...filters, fecha_fin: date });
        } else {
            // Reset selection if both dates are already selected or the selected date is before the start date
            setFilters({ ...filters, fecha_inicio: date, fecha_fin: null });
        }
    };

    return (
        <>
            <div className="flex flex-col w-full p-4">
                {/* Título */}
                <div className="flex flex-row">
                    <p className="font-bold text-xl">Banca - Periodo </p>
                </div>
                {/* Secciones */}
                <div className="grid grid-cols-4 gap-4 mt-6">
                    {/* Columna ONE2ONE */}
                    <div>
                        <h3 className="font-bold text-blue-500 text-lg mb-4">ONE2ONE</h3>
                        <GenericCard name="Utilidad bruta" text={formatCurrencyUsd(data.netos)} />
                        <GenericCard name="Monto facturado" text={formatCurrencyUsd(data.facturado)} />
                    </div>
                    {/* Columna CLIENTES */}
                    <div>
                        <h3 className="font-bold text-pink-500 text-lg mb-4">CLIENTES</h3>
                        <GenericCard name="Ingreso total" text={formatCurrencyUsd(data.recibidos)} />
                        <GenericCard name="Costo indirecto de ingresos" text={formatCurrencyUsd(data.comisionesPagosRecibidos)} />
                    </div>
                    {/* Columna ENFERMEROS */}
                    <div>
                        <h3 className="font-bold text-green-500 text-lg mb-4">ENFERMEROS</h3>
                        <GenericCard name="Costo directo" text={formatCurrencyUsd(data.realizados)} />
                        <GenericCard name="Costo indirecto de egresos" text={formatCurrencyUsd(data.comisionesPagosRealizados)} />
                    </div>
                    <div className="mt-6 flex justify-end">
                        <DatePicker
                            selected={new Date(filters.fecha_inicio)} // Show the start date as selected
                            onChange={handleDateChange}
                            selectsRange={false} // Disable selectsRange for single date selection mode
                            startDate={new Date(filters.fecha_inicio)} // Start of the range
                            fecha_fin={new Date(filters.fecha_fin)} // End of the range
                            inline
                            dateFormat="yyyy/MM/dd"
                            dayClassName={(date) =>
                                new Date(filters.fecha_inicio) && new Date(filters.fecha_fin) && date >= new Date(filters.fecha_inicio) && date <= new Date(filters.fecha_fin)
                                    ? 'bg-blue-200'
                                    : undefined
                            }
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default HeadBanca