import React, { useEffect, useRef, useState } from "react";
import CustomButton from "../../form/CustomButton";
import CustomInput from "../../form/CustomInput";
import { FaTrash, FaFileUpload } from "react-icons/fa";
import { getEspecialidades } from "../../../helpers/configRoutes/backend_helper";
import CustomSelect from "../../form/CustomSelect";
import FileInput from "../../form/FileInput";

const EstudiosEdit = ({ item, editItem, onDelete, validation }) => {
    const fileInputRefs = useRef([]);
    const [especialidadCatalogo, setEspecialidadCatalogo] = useState([]);
    const [modal, setModal] = useState({ show: false })

    useEffect(() => {
        const getCatalogos = async () => {
            const response = await getEspecialidades({ params: { especialidad_id: validation.values.estudios } })
            setEspecialidadCatalogo(response.especialidades)
        }
        if (validation.values?.estudios) {
            getCatalogos()
        }

    }, [validation.values?.estudios]);

    const [experiencias, setExperiencias] = useState([
        { id: 1, cargo: '', actividades: '', fecha_inicio: '', fecha_inicio: '' }
    ]);

    const addExperienciaAcademica = () => {
        const newObjeto = { id: (experiencias.length + 1), institucion: '', estudios: '', fecha_inicio: '', fecha_inicio: '', formacion: true, comprobante: '' }
        validation.setFieldValue('formacion_academica', [...validation.values.formacion_academica, newObjeto])
    }

    return (
        <>
            <div className="flex flex-col">
                <div className="flex flex-row items-center w-full pl-6 space-x-2">
                    <p className="font-bold text-sm whitespace-nowrap">Formación académica</p>
                    <hr className="flex-1 ml-2" />
                </div>
                {validation.values?.formacion_academica?.map((e, i) => (
                    <div key={i} className="flex flex-row justify-between pl-6 mt-4">
                        {/* Periodo */}
                        <div className="flex flex-col flex-1">
                            <CustomInput
                                name={`formacion_academica[${i}].fecha_inicio`}
                                value={
                                    e.fecha_inicio ||
                                    ""
                                }
                                onChange={validation.handleChange}
                                placeholder="Fecha inicio"
                                type="date"
                                error={validation.errors?.formacion_academica?.[i]?.fecha_inicio}
                                className="text-sm mb-0"
                            />
                            <p className="text-xs">Fecha inicio</p>
                            <CustomInput
                                name={`formacion_academica[${i}].fecha_fin`}
                                value={
                                    e.fecha_fin ||
                                    ""
                                }
                                onChange={validation.handleChange}
                                placeholder="Fecha fin"
                                type="date"
                                error={validation.errors?.formacion_academica?.[i]?.fecha_fin}
                                className="text-sm"
                            />
                            <p className="text-xs">Fecha final</p>
                        </div>

                        {/* Institución */}
                        <div className="flex flex-col flex-1">
                            <CustomInput
                                name={`formacion_academica[${i}].institucion`}
                                value={
                                    e.institucion ||
                                    ""
                                }
                                onChange={validation.handleChange}
                                placeholder="Institución"
                                error={validation.errors?.formacion_academica?.[i]?.institucion}
                                type="text"
                                className="text-sm"
                            />

                            <p className="text-xs">Institución</p>
                        </div>

                        {/* Estudios */}
                        <div className="flex flex-col flex-1">

                            <CustomSelect
                                name={`formacion_academica[${i}].especialidad_id`}
                                options={especialidadCatalogo}
                                value={e.especialidad_id || ''}
                                error={validation.errors?.formacion_academica?.[i]?.especialidad_id}
                                onChange={validation.handleChange}
                            />
                            <p className="text-xs">Estudios</p>
                        </div>

                        {/* Íconos de agregar/ver documento y eliminar */}
                        <div className="flex flex-col flex-1 justify-center items-center">
                            <div className="flex items-center space-x-4">
                                <FileInput
                                    format={'pdf'}
                                    name={`formacion_academica[${i}].comprobante`}
                                    validation={validation}
                                    nameInput={'Subir archivo'}
                                />
                            </div>
                        </div>
                    </div>
                ))}

            </div>
            <div className="flex flex-wrap max-w-[90%] ml-[1%] mt-[10px] max-md:max-w-full max-md:ml-0  font-bold">
                <div className='flex flex-row cursor-pointer w-52' onClick={() => addExperienciaAcademica()}>
                    <span className="material-symbols-outlined my-auto">
                        add_circle
                    </span>
                    <p className='my-auto ml-2 font-bold'>Agregar otra especialidad</p>
                </div>
            </div>
        </>
    );
};

export default EstudiosEdit;
