import React, { useEffect, useState } from "react";
import BaseModalAnimated from "../../../components/modal/BaseModalAnimated";
import { serviciosResource } from "../../../helpers/configRoutes/backend_helper";
import SingleCalendarDateRangePicker from "../../../components/form/SingleCalendarDateRangePicker";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/form/CustomButton";
import { formatCurrencyUsd } from "../../../helpers/Formatos";
import CustomButtonOval from "../../../components/form/CustomButtonOval";
import FacturaCitasModal from "./FacturaCitasModal";

const ModalServicios = ({ modal, setModal }) => {
    const navigate = useNavigate()
    const [showCalendar, setShowCalendar] = useState(false);
    const [servicios, setServicios] = useState([])
    const [modalFact,setModalFact]=useState({title:'Facturas de servicio'})
    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0]; // Formato YYYY-MM-DD
    };

    const getFirstDayOfMonth = () => {
        const today = new Date();
        const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
        return firstDay.toISOString().split('T')[0]; // Formato YYYY-MM-DD
    };

    const [filters, setFilters] = useState({
        perPage: 20,
        page: 1,
        fecha_inicio: getFirstDayOfMonth(),
        fecha_fin: getCurrentDate(),
        enfermero_id:modal.id
    });

    useEffect(() => {
        const getServicios = async () => {
            const response = await serviciosResource('get', { params: filters });
            setServicios(response.servicios);
        };
        if (modal.id) {
            getServicios();
        }
    }, [modal.id, filters]);

    return (
        <BaseModalAnimated setModal={setModal} modal={modal}
            headerRight={
                <div className="relative">
                    {!showCalendar ? (
                        <div className="flex items-center space-x-2 border-r border-gray-100 pr-4" onClick={() => setShowCalendar(!showCalendar)}>
                            <div>
                                {(filters.fecha_inicio && filters.fecha_fin) && (
                                    <p>
                                        {new Date(filters.fecha_inicio).toLocaleDateString()} to {new Date(filters.fecha_fin).toLocaleDateString()}
                                    </p>
                                )}
                            </div>
                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                            </svg>
                        </div>
                    ) : (
                        <div className="absolute top-full mt-2 left-0 z-50 bg-white shadow-lg p-4 border border-gray-200">
                            <SingleCalendarDateRangePicker filters={filters} setFilters={setFilters} setShowCalendar={setShowCalendar} showCalendar={showCalendar} />
                            <div className="mt-2">
                                {filters.fecha_inicio && <p>Start Date: {new Date(filters.fecha_inicio).toLocaleDateString()}</p>}
                                {filters.fecha_fin && <p>End Date: {new Date(filters.fecha_fin).toLocaleDateString()}</p>}
                            </div>
                        </div>
                    )}
                </div>
            }
        >
            <div className="p-4">

                <h2 className="text-center text-[22px]"></h2>
                <p className="text-[22px]"></p>
            </div>
            {/* Lista de motivos */}
            <div className="grid grid-cols-3 gap-4 ">
                {servicios.length > 0 ? (
                    servicios.map((s, index) => (
                        <div className="flex items-center select-none" key={index}>
                            <div className="flex flex-col w-full h-full bg-gray-200 m-2 p-2 rounded relative">
                                <div className="flex flex-row w-full">
                                    <div className="flex flex-col w-full">
                                        <p className="font-bold text-base">{s?.paciente.nombre} {s?.paciente.apellidos}</p>
                                        <p className="text-xs">Paciente</p>
                                        <p className="font-bold text-base">{s?.fecha_inicial} al {s?.fecha_fin}</p>
                                        <p className="text-xs">Periodo</p>
                                        {s.paciente?.cuidados?.map((c, i) => (
                                            <CustomButtonOval
                                                key={i}
                                                className={'text-xs  bg-o2o-gray-title'}
                                            >
                                                {c.nombre}
                                            </CustomButtonOval>
                                        ))}

                                        <p className="font-bold text-base">{formatCurrencyUsd(s?.pagos?.costo_total)}</p>
                                        <p className="text-xs">Pago por servicio</p>
                                    </div>
                                </div>
                                <div className="flex flex-col w-full pt-2">
                                    <CustomButton
                                        onClick={() => setModalFact({...modalFact,show:true,id:s.id})}
                                        className="bg-black hover:bg-slate-600 mx-2 text-white text-center px-1 rounded text-sm"
                                    >
                                        Ver facturas
                                    </CustomButton>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="text-center w-full">No hay datos disponibles.</p>
                )}
            </div>


            {/* Pie del modal */}
            <div className="flex items-center justify-center p-6 rounded-b border-slate-200">

            </div>
            {modalFact.show &&
                <FacturaCitasModal modal={modalFact} setModal={setModalFact}/>
            }
            
        </BaseModalAnimated>
    )
}

export default ModalServicios