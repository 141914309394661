import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { clientsResource } from "../../helpers/configRoutes/backend_helper";
import CustomTabs from "../../components/dashboard/nurces/tab/CustomTabs";
import ClienteID from "./ClienteID";
import Pacientes from "../pacientes/Pacientes";
import HistorialServicios from "../servicios/HistorialServicios";
import CustomButton from "../../components/form/CustomButton";
import BloquearClienteModal from "./BloquearClienteModa";

const Administrador = ({ children }) => {
    const { id, tab } = useParams();
    const navigate = useNavigate([]);
    const [item, setItem] = useState({});
    const [tabsAdmin, setTabs] = useState([]);
    const [modal, setModal] = useState({ show: false })

    useEffect(() => {
        const getItem = async () => {
            try {
                let response = { status: false };
                response = await clientsResource('show', {}, id);
                setItem(response.cliente);
                if (!children) {
                    setTabs([
                        { id: 1, label: 'Información general', content: <ClienteID item={response.cliente} /> },
                        { id: 2, label: 'Pacientes registrados', content: <Pacientes item={response.cliente} /> },
                        { id: 3, label: 'Historial de servicios', content: <HistorialServicios item={response.cliente} /> },
                    ])
                } else {
                    setTabs([
                        { id: 1, label: 'Información general', content: <ClienteID item={response.cliente} /> },
                        { id: 2, label: 'Pacientes registrados', content: <>{children}</> },
                        { id: 3, label: 'Historial de servicios', content: <HistorialServicios item={response.cliente} /> },
                    ])
                }

            } catch (error) {
                console.error('Error al obtener los datos:', error);
            }
        }
        if (id) {
            getItem();
        }
    }, [id]);

    return (
        <>
            <div className="flex justify-between items-center mb-[43px]">
                <div className="flex items-center space-x-2">
                    <button
                        onClick={() => navigate('/clientes')}
                        className="text-blue-500 font-semibold">
                        &lt;
                    </button>
                    <h1 className="font-semibold text-lg">Cliente.</h1>
                </div>
                <div className="flex flex-col space-y-2 mt-4">

                    <CustomButton
                        className="bg-gray-400 text-white font-semibold py-2 px-4 rounded"
                        onClick={() => setModal({ ...modal, show: true, id:id,item:item })}>
                        Bloquear cuenta
                    </CustomButton>

                </div>
            </div>
            <CustomTabs tabs={tabsAdmin} defaultTab={children ? 2 : (parseInt(tab))} />
            {(modal.show && id)&&
                <BloquearClienteModal modal={modal} setModal={setModal}/>
            }
        </>
    )


}
export default Administrador;