import React, { useEffect, useState } from "react";
import BaseModalAnimated from "../../components/modal/BaseModalAnimated";
import { useNavigate } from "react-router-dom";
import StarRating from "../../components/starRating/StarRating";
import { formatCurrencyUsd } from "../../helpers/Formatos";
import CustomButton from "../../components/form/CustomButton";
import { getFactura, servicioFacturar } from "../../helpers/configRoutes/backend_helper";

const DetallesModal = ({ modal, setModal, filters, setFilters }) => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const action = async () => {
        setLoading(true)
        if (modal.item.estatus_id == 11) {
            setModal({ show: true, type: 1, title: `¿Confirmas que recibiste el monto de la transacción ${modal.item.stripe_id}?`, item: modal.item })
        }

        if (modal.item.estatus_id == 10) {
            if (!modal.item.factura_pdf) {
                let data = { ...modal.data, id_factura: modal.item.stripe_id, pago_descripcion: modal.item.concepto, pago_id: modal.item.id_data }
                const response = await servicioFacturar(data);
                if (response.status) {
                    let docFactura = await getFactura({ pago_id: modal.item.id_data });
                    setFilters({ ...filters, tab: filters.tab })
                    if (docFactura.status) {
                        if (!docFactura.pdf_doc) {
                            docFactura = await getFactura({ pago_id: modal.item.id_data });
                        }
                        modal.item.factura_pdf = docFactura.pdf_doc;
                        setModal({ ...modal, show: true, item: modal.item, title: 'Facturas', firma: true, type: 3, url: docFactura.pdf_doc })
                    }
                    setLoading(false)
                }

                setLoading(false)
            }
            setModal({ ...modal, show: true, item: modal.item, title: 'Facturas', firma: true, type: 3, url: modal.item.factura_pdf })
        }
        setLoading(true)
    }
    return (
        <BaseModalAnimated setModal={setModal} modal={modal}>
            {console.log(modal, 'dataModal')}
            <div className="p-4">

                <h2 className="text-center text-[22px]"></h2>
                <p className="text-[22px]"></p>
            </div>
            {/* Lista de motivos */}
            <div className="grid">
                <div className="p-6 space-y-4">
                    {/* Cliente y servicios */}
                    <div className="flex justify-between items-start">
                        <div className="w-12 h-12 flex-shrink-0">
                            <img
                                src={modal.data?.administrador.perfilImg || '/public/images/usrAnonimo.png'}
                                alt="Perfil"
                                className="w-full h-full rounded-full object-cover"
                            />
                        </div>
                        <div>
                            <p className="text-sm font-bold">Cliente y servicios que solicitó</p>
                            <p className="text-lg font-medium">{modal.data?.administrador.nombre} {modal.data?.administrador.apellidos}</p>
                            <div className="flex space-x-2 mt-2">
                                {modal.data?.paciente.cuidados.map((c, i) => (
                                    <span key={i} className="bg-o2o-secondary text-sm text-white px-2 py-1 rounded">{c.nombre}</span>
                                ))}
                            </div>
                        </div>
                        <div className="text-sm">
                            <p className="font-bold">Período de servicio</p>
                            <ul className="list-disc list-inside">
                                <li>{modal.data?.fecha_inicial} a {modal.data?.fecha_fin}</li>
                            </ul>
                        </div>
                    </div>

                    {/* Enfermero */}
                    <div className="border-t pt-4">
                        <div className="flex items-center space-x-4">
                            {/* Imagen del perfil */}
                            <div className="w-12 h-12 flex-shrink-0">
                                <img
                                    src={modal.data?.enfermero.perfilImg || '/public/images/usrAnonimo.png'}
                                    alt="Perfil"
                                    className="w-full h-full rounded-full object-cover"
                                />
                            </div>

                            {/* Información del enfermero */}
                            <div>
                                <p className="text-sm font-bold">Enfermero</p>
                                <p className="text-lg font-medium">{modal.data?.enfermero.nombre} {modal.data?.enfermero.apellidos}</p>
                                <span className="bg-blue-100 text-blue-600 text-xs px-2 py-1 rounded">{modal.data?.enfermero.especialidad}</span>
                            </div>
                        </div>

                        <div className="flex mt-4 space-x-4">
                            <div>
                                <p className="font-medium">{modal.data?.enfermero.movil}</p>
                                <p className="text-xs text-gray-500">móvil</p>
                            </div>
                            <div>
                                <p className="font-medium">{modal.data?.enfermero.email}</p>
                                <p className="text-xs text-gray-500">mail</p>
                            </div>
                        </div>
                    </div>

                    {/* Detalles */}
                    <div className="border-t pt-4">
                        <p className="text-sm font-bold mb-2">Detalles</p>
                        <div className="flex items-center mb-4">
                            <StarRating rating={modal.item?.type == 2 ? modal.data?.calificacion : 0} />
                        </div>

                        <div className="text-sm">
                            {
                                <>
                                    <p className="flex justify-between text-base">
                                        <span>ID Transacción {modal.item.stripe_id ?? modal.id}</span>
                                        <span>Fecha y hora {modal.item.created_at}</span>
                                    </p>
                                    {modal.item?.type == 2 &&
                                        <>
                                            <p className="flex justify-between mt-10">
                                                <span>+ Total del servicio:</span>
                                                <span>{formatCurrencyUsd(modal.item.cantidad)}</span>
                                            </p>
                                            <p className="flex justify-between">
                                                <span>- Comisiones de transacción:</span>
                                                <span>{formatCurrencyUsd(modal.item.comision)}</span>
                                            </p>
                                            <p className="flex justify-between mt-5">
                                                <span></span>
                                                <span>Monto final a recibir {formatCurrencyUsd(modal.item.cantidad - modal.item.comision)}</span>
                                            </p>
                                        </>
                                    }

                                    {modal.item?.type == 3 &&
                                        modal.data?.citas.map((c, i) => (
                                            <p className="flex justify-between" key={i}>
                                                <span>+ Total turno facturado. Turno: #{c.cita.cita_no}</span>
                                                <span>{formatCurrencyUsd(c.cantidad)}</span>
                                            </p>
                                        ))

                                    }
                                    {modal.item?.type == 4 &&
                                        <>
                                            <p className="flex justify-between font-bold mt-4" >
                                                <span>Motivo de reembolso:</span>
                                            </p>
                                            <p className="flex justify-between font-bold mt-4" >
                                                <span>{modal.item.politica?.nombre}</span>
                                                <span>{modal.item.politica?.descripcion}</span>
                                            </p>
                                        </>

                                    }
                                </>
                            }
                        </div>
                        {modal.item?.type == 3 &&
                            <p className="flex justify-between font-bold mt-4">
                                <span>Monto final a pagar</span>
                                <span>{formatCurrencyUsd(modal.item.type == 2 ? (modal.item?.cantidad - modal.item?.comision) : modal.item?.cantidad)}</span>
                            </p>
                        }
                    </div>
                    {/* Botón de acción */}
                </div>
            </div>

            {/* Pie del modal */}
            <div className="flex items-center justify-center p-6 rounded-b border-slate-200">
                {(modal.item.type == 2 && !modal.item.reecompenza) &&
                    <CustomButton loading={loading} textLoad={'Procesando dispersión'} className={"bg-black text-white w-full py-3 rounded text-lg hover:bg-gray-800"} onClick={() => action()}>
                        {modal.item.estatus_id == 10 ?
                            <>
                                {modal.item.factura_pdf ?
                                    'Descargar recibo'
                                    :
                                    'Facturar'
                                }
                            </>
                            :
                            'Confirmar de recibido'
                        }

                    </CustomButton>
                }
            </div>
        </BaseModalAnimated>
    )
}

export default DetallesModal