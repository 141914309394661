import {
    SET_PACIENTES,
    SET_PACIENTE
  } from "./actionTypes"
  
  export const set_pacientes = data => {
    //data={pacientes:[......], cliente_id:X}
    return {
      type: SET_PACIENTES,
      payload: data,
    }
  }

  export const set_paciente = paciente => {
    return {
        type:SET_PACIENTE,
        payload:paciente
    }
  }
