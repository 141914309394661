import React, { useState } from "react";
import CustomButton from "../../components/form/CustomButton";
import CustomButtonOval from "../../components/form/CustomButtonOval";
import { formatCurrencyUsd } from "../../helpers/Formatos";

const HistorialServicios = ({ item }) => {
    const [selectedButton, setSelectedButton] = useState();
    const [servicios, setServicios] = useState([])

    const handleButtonClick = (button) => {
        setSelectedButton(button); // Marca el botón como seleccionado
        const newArray = item.servicios.filter((s) => s.estatus_id === button)
        setServicios(newArray)
    };
    const formatFecha = (fecha) => {
        if (!fecha) return '';
        const opciones = { day: 'numeric', month: 'long', year: 'numeric' };
        return new Date(fecha).toLocaleDateString('es-ES', opciones);
    };

    return (
        <div className="tab-content tab-content-active space-y-8 bg-white p-6 rounded-md shadow-md">
            <div className="flex items-center justify-center">
                <CustomButtonOval
                    onClick={() => handleButtonClick(9)}
                    className={`${selectedButton === 9
                        ? "bg-black text-white"
                        : "bg-o2o-gray-primary"
                        } px-4 py-2 rounded`}
                    style={{ width: "194px", height: "22px" }}
                >
                    Servicios Activos
                </CustomButtonOval>
                <CustomButtonOval
                    onClick={() => handleButtonClick(5)}
                    className={`${selectedButton === 5
                        ? "bg-black text-white"
                        : "bg-o2o-gray-primary"
                        } px-4 py-2 rounded`}
                    style={{ width: "194px", height: "22px" }}
                >
                    Solicitudes Enviadas
                </CustomButtonOval>
                <CustomButtonOval
                    onClick={() => handleButtonClick(6)}
                    className={`${selectedButton === 6
                        ? "bg-black text-white"
                        : "bg-o2o-gray-primary"
                        } px-4 py-2 rounded`}
                    style={{ width: "194px", height: "22px" }}
                >
                    Servicios Concluidos
                </CustomButtonOval>
            </div>
            {servicios.map((s, i) => (
                
                    <div className="flex flex-wrap lg:flex-nowrap items-center justify-between bg-gray-100 p-4 rounded-lg shadow-md" key={i}>
                        <div className="grid grid-cols-6 gap-4">
                            <div className="p-4">
                                <div className='flex flex-col flex-1'>
                                    <p className='font-medium text-lg'>{s?.enfermero?.nombre} {s?.enfermero?.apellidos}</p>
                                    <p className='text-sm'>Enfermero</p>
                                </div>
                            </div>
                            <div className="p-4">
                                <div className='flex flex-col flex-1'>
                                    <p className='font-medium text-lg'>{s?.paciente?.nombre}</p>
                                    <p className='text-sm'>Paciente</p>
                                </div>
                            </div>
                            <div className="p-4">
                                <div className='flex flex-col flex-1'>
                                    <p className='font-medium text-lg'>
                                        {formatFecha(s?.fecha_inicial)} al {formatFecha(s?.fecha_fin)}
                                    </p>
                                    <p className='text-sm'>Periodo del servicio</p>
                                </div>
                            </div>
                            <div className="p-4">
                                <div className='flex flex-col flex-1'>
                                    {s?.paciente?.cuidados.map((cuidados, index) => (
                                        <span
                                            key={index}
                                            className="flex items-center bg-[#E5E5E5] px-3 py-1 rounded-full border border-gray-400 text-sm mb-1"
                                        >
                                            {cuidados.nombre}
                                        </span>
                                    ))}
                                </div>
                            </div>
                            <div className="p-4">
                                <div className='flex flex-col flex-1'>
                                    <p className='font-medium text-lg'>{formatCurrencyUsd(s?.pagos?.costo_total)}</p>
                                    <p className='text-sm'>Pago por servicio</p>
                                </div>
                            </div>
                            
                                <div className="p-4">
                                    <div className='flex flex-col flex-1'>
                                    
                                        <CustomButtonOval className="bg-black text-white px-4 py-2 rounded"
                                        isDisabled={s.factura_cliente ? false:true}
                                        >
                                            Ver factura
                                        </CustomButtonOval>
                                    </div>
                                </div>
                            
                        </div>
                    </div>
                
            ))}
        </div>
    );
};

export default HistorialServicios;
