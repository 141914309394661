import React, { useEffect, useState } from "react";
import Columns from "./Columns";
import HeadBanca from "./HeadBanca";
import CustomSelect from "../../components/form/CustomSelect";
import CustomSearchInput from "../../components/form/CustomSearchInput";
import DataTableElement from "../../components/elements/DataTableElement";
import { getBancoData } from "../../helpers/configRoutes/backend_helper";
import ConfirmarPagoModal from "./Pagos/ConfirmarPagoModal";
import DetallesModal from "./DetallesModal";
import FacturaVisualizarModal from "./Pagos/FacturaVisualizaModal";
import PagoModal from "./Enfermero/PagoModal";

const Banco = () => {
    const [facturas, setFacturas] = useState(false)
    const [tableLoad, setTableLoad] = useState(true);
    //1 Confirmar Pago 
    const [modal, setModal] = useState({ show: false, type: 1 });
    const [estatus, setEstatus] = useState([])
    const [filters, setFilters] = useState({ tab: 1, search: '', orderBy: '', estatus_id: '', fechaOrder: '' });
    const [transacciones, setTransacciones] = useState([])

    const tabs = [
        { id: 1, nombre: "One2One (Todos)" },
        { id: 2, nombre: "Por recibir" },
        { id: 3, nombre: "Por pagar" },
        { id: 4, nombre: "Reembolsos/Cancelación" }
    ]

    const cols = Columns(filters.tab, setModal);

    useEffect(() => {
        const getData = async () => {
            setTableLoad(true)
            const response = await getBancoData({ params: filters });
            setTransacciones(response.data)
            if (estatus.length == 0) {
                setEstatus(response.estatus)
                setTableLoad(false)
            }
            setTableLoad(false)
        };
        getData();
    }, [filters]);

    return (
        <>
            <HeadBanca filtersDad={filters} setFiltersDad={setFilters} />
            <div className="p-6">
                {/* Tabs */}
                <div className="flex border-b mb-4">
                    {tabs.map((tab, index) => (
                        <button
                            key={index}
                            className={`px-4 py-2 font-medium ${filters.tab == tab.id
                                ? "border-b-2 border-blue-500 text-blue-500"
                                : "text-gray-500"
                                }`}
                            onClick={() => setFilters({ ...filters, tab: tab.id })}
                        >
                            {tab.nombre}
                        </button>
                    ))}
                </div>

                {/* Filtros */}
                <div className="flex items-center justify-between mb-4">
                    <div className="flex space-x-4">
                        <CustomSelect
                            className={'border px-4 py-2 rounded'}
                            options={estatus}
                            holder={'Estado'}
                            value={filters.estatus_id}
                            handleChange={(e) => setFilters({ ...filters, estatus_id: e.target.value })}
                        />
                        <CustomSelect
                            className={'border px-4 py-2 rounded'}
                            options={[{ id: 1, nombre: 'Más reciente' }, { id: 2, nombre: 'Más antiguo' }]}
                            holder={'Fecha'}
                            value={filters.fechaOrder}
                            handleChange={(e) => setFilters({ ...filters, fechaOrder: e.target.value })}
                        />
                    </div>
                    <div className="w-1/3">
                        <CustomSearchInput className={'border px-4 py-2 rounded w-1/3'} holder={"Buscador por número de transacción"} handleChange={(e) => setFilters({ ...filters, search: e.target.value })} value={filters.search} />
                    </div>
                </div>

                {/* Tabla */}
                <div className="overflow-x-auto">
                    <DataTableElement columns={cols} tableLoad={tableLoad} items={transacciones} />
                </div>
            </div>
            {(modal.show && modal.type == 1) &&
                <ConfirmarPagoModal modal={modal} setModal={setModal} filters={filters} setFilters={setFilters} />
            }
            {(modal.show && modal.type == 2) &&
                <DetallesModal modal={modal} setModal={setModal} filters={filters} setFilters={setFilters} />
            }
            {(modal.show && modal.type == 3) &&
                <FacturaVisualizarModal modal={modal} setModal={setModal} />
            }
            {(modal.show && modal.type == 4) &&
                <PagoModal modal={modal} setModal={setModal} facturas={false} setFacturas={setFacturas} filters={filters} setFilters={setFilters} />
            }

        </>
    );
}

export default Banco