import React, { useEffect, useState } from "react";
import BaseModalAnimated from "../../components/modal/BaseModalAnimated";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/form/CustomButton";
import { clientsResource } from "../../helpers/configRoutes/backend_helper";


const BloquearClienteModal = ({ modal, setModal}) => {
    const navigate = useNavigate();
    const [loading,setLoading] = useState(false)
    const bloquear = async () => {
        // Marcar que el proceso está en curso
        setLoading(true);
    
        try {
            const respuesta = await clientsResource('del', {}, modal.item.id);
            console.log(respuesta);
            if (respuesta.status) {
                navigate('/clientes');
                setLoading(false);
            }
            setLoading(false);
        } catch (error) {
            console.error("Error al bloquear el cliente:", error);
            setLoading(false);
        } finally {
            // Restaurar el estado de carga
            setLoading(false);
        }
    };
    

   
    return (
        <BaseModalAnimated setModal={setModal} modal={modal}>
            {`¿Estás seguro de bloquear la cuenta de ${modal.item?.nombre} ${modal.item?.apellidos}?`}
            <div className="flex items-center justify-center p-6 rounded-b border-slate-200">
                <CustomButton
                    isDisabled={modal.loading}
                    textLoad={'Cancel'}
                    type={"success"}
                    onClick={() => setModal({ ...modal, show: false })}
                    className="w-full lg:w-[30%] bg-o2o-gray-title hover:bg-mts-red-hover"
                >
                    {'Cancelar'}
                </CustomButton>

                <CustomButton
                    loading={loading}
                    textLoad={'Bloqueando'}
                    type={"success"}
                    onClick={bloquear}
                    className="w-full lg:w-[30%]"
                >
                    {'Aceptar'}
                </CustomButton>

            </div>

        </BaseModalAnimated>
    )
}

export default BloquearClienteModal