import { SET_PACIENTES,SET_PACIENTE } from "./actionTypes";

const initialState = {
    cliente_id:null,
    pacientes: [],
    paciente:{
        id:null
    }
};

const pacientesState = (state = initialState, action) => {
    switch (action.type) {
        case SET_PACIENTES:
            // Actualizar el estado
            const newState = {
                ...state,
                pacientes: action.payload.pacientes,
                cliente_id: action.payload.cliente_id
            };
            return newState;
        case SET_PACIENTE:
                // Actualizar el estado
                const newPaciente = {
                    ...state,
                    paciente: action.payload
                };
                return newPaciente;
        default:
            return state;
    }
};

export default pacientesState;
