import { useNavigate } from "react-router-dom";

export default function CustomInput({
    forgotPassword,
    label,
    value,
    name,
    type,
    handleChange,
    error,
    className,
    labelClassName,
    isTextArea = false, // Prop para indicar si debe renderizar un textarea
    ...props
}) {
    const inputClassName = `border pl-4 rounded-md ${isTextArea ? 'h-auto' : 'h-12'} ${
        error ? 'border-red-600' : 'border-black'
    } bg-mts-gray ${className}`;
    const labelBtnClassName = `font-normal text-sm ${labelClassName}`;
    const navigate = useNavigate();

    const ErrorMensaje = () => {
        if (error) {
            return <span className="text-red-600">{error}</span>;
        }
        return null;
    };

    return (
        <div className="flex flex-col w-full py-1">
            <label className={labelClassName}>{label}</label>

            {forgotPassword != undefined && (
                <label
                    className={`${labelClassName} text-right cursor-pointer`}
                    onClick={() => navigate('/forgotPassword')}
                >
                    Forget Password?
                </label>
            )}

            {isTextArea ? (
                <textarea
                    value={value}
                    name={name}
                    onChange={handleChange}
                    className={inputClassName}
                    {...props}
                />
            ) : (
                <input
                    type={type}
                    value={value}
                    name={name}
                    onChange={handleChange}
                    className={inputClassName}
                    {...props}
                />
            )}

            <ErrorMensaje />
        </div>
    );
}
