import React, { useEffect, useState } from "react";
import BaseModalAnimated from "../../../components/modal/BaseModalAnimated";
import { facturasEnfermeroResource, getDeclinar, serviciosResource } from "../../../helpers/configRoutes/backend_helper";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/form/CustomButton";
import { formatCurrencyUsd } from "../../../helpers/Formatos";
import PreviewPDF from "../../../components/modal/PreviewPdf"
import CustomInput from "../../../components/form/CustomInput";

const RazonBloqueoModal = ({ modal, setModal }) => {
    const navigate = useNavigate()
    const [motivos, setMotivos] = useState([]);
    const [motivoText, setMotivoText] = useState(modal.item.motivo);
    const selectedMotivos = modal.item.motivos;

    useEffect(() => {
        const getMotivosData = async () => {
            const response = await getDeclinar('get', { params: { model: (modal.model || 'SolicitudTrabajo') } });
            if (response.status) {
                setMotivos(response.motivos);
            }
        }
        getMotivosData();
    }, []);

    return (
        <BaseModalAnimated setModal={setModal} modal={modal}>
            {/* Lista de motivos */}
            <div className="p-4">
                {motivos && motivos.length > 0 ? (
                    motivos.map((row) => (
                        <label
                            key={row.id}
                            className="flex items-center my-2 cursor-pointer group"
                        >
                            <input
                                type="checkbox"
                                name="row"
                                value={row.id}
                                checked={selectedMotivos.includes(row.id)}
                                onChange={() => console.log(row.id)}
                                className="hidden"
                            />
                            <div
                                className={`relative w-6 h-6 border-4 rounded flex items-center justify-center transition-all duration-300 ${selectedMotivos.includes(row.id)
                                    ? 'border-blue-500' // Color más marcado cuando está seleccionado
                                    : 'border-gray-400' // Color del borde cuando no está seleccionado
                                    }`}
                            >
                                {selectedMotivos.includes(row.id) && (
                                    <div className="w-3.5 h-3.5 bg-blue-500 rounded transition-all duration-300"></div>
                                )}
                            </div>
                            <span className="ml-3 text-gray-700 group-hover:text-blue-500 transition-colors duration-300">
                                {row.motivo}
                            </span>
                        </label>
                    ))
                ) : (
                    <p>No hay datos disponibles para mostrar.</p>
                )}
                <h2 className="text-xl mt-4 mb-5">Descripcion del problema</h2>
                <p className="m-3">
                    {modal.item.motivo}
                </p>
            </div>

            {/* Pie del modal */}
            <div className="flex items-center justify-center p-6 border-t border-gray-300">
                <button
                    className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded"
                    onClick={() => setModal({ show: false })}
                >
                    Cerrar
                </button>
            </div>

        </BaseModalAnimated >
    )
}

export default RazonBloqueoModal