import React from "react";
import { dangerToast } from "../../../helpers/Toast";

const AreasEspecial = ({ item, editItem, validation, categorias }) => {

    const agregarInteresesPersonales = (id, categoria) => {
        const interesesActuales = validation.values.acreditaciones?.servicios || [];

        let nuevosIntereses = [];
        if (interesesActuales.filter(interes => interes.id == id).length > 0) {
            nuevosIntereses = interesesActuales.filter(interes => interes.id !== id)
            validation.setFieldValue('acreditaciones.servicios', nuevosIntereses);
        } else {
            const interesesActualesCategory = interesesActuales.filter(intereses => intereses.categoria_id == categoria)
            if (interesesActualesCategory.length < 5) {
                nuevosIntereses = [...interesesActuales, { id: id, categoria_id: categoria }];
                validation.setFieldValue('acreditaciones.servicios', nuevosIntereses);
            } else {
                dangerToast('Solo puedes seleccionar maximo 5 intereses personales');
            }
        }
    }

    return (
        <>
            <div className="flex flex-row flex-wrap gap-2">
                <div className="flex flex-row items-center w-full pl-6 space-x-2">
                    <p className="font-bold text-sm whitespace-nowrap">Áreas de la salud en las que se especializa</p>
                    <hr className="flex-1 ml-2" />
                </div>
                {editItem ?
                    <>
                        {categorias.filter((c) => c.id === 6)[0]?.ofertas.map((data, index) => {
                            return (
                                <button
                                    type="button"
                                    key={index}
                                    className={`justify-center px-2.5 py-1 whitespace-nowrap rounded-md border border-solid border-stone-100 hover:bg-o2o-gris-calido ${validation.values.acreditaciones?.servicios?.length > 0 ? (validation.values.acreditaciones?.servicios?.filter(res => res?.id === data?.id).length > 0 ? 'bg-o2o-gris-calido-hover' : '') : ''}`}
                                    onClick={() => agregarInteresesPersonales(data?.id, data.categoria_id)}
                                >
                                    {data.nombre}
                                </button>
                            )
                        })}
                    </> :
                    <>
                        {
                            item?.acreditaciones?.servicios?.map((servicios, index) => (
                                <span
                                    key={index}
                                    className="flex items-center bg-[#E5E5E5] px-4 rounded-full border border-black h-6"
                                >
                                    {servicios.nombre}
                                </span>
                            ))}
                    </>}

                <div className="flex flex-row items-center w-full pl-6 space-x-2">
                    <p className="font-bold text-sm whitespace-nowrap">Perfil de pacientes con mayor experiencia </p>
                    <hr className="flex-1 ml-2" />
                </div>
                {editItem ?
                    <>
                        {categorias.filter((c) => c.id === 7)[0]?.ofertas.map((data, index) => {
                            return (
                                <button
                                    type="button"
                                    key={index}
                                    className={`justify-center px-2.5 py-1 whitespace-nowrap rounded-md border border-solid border-stone-100 hover:bg-o2o-gris-calido-hover ${validation.values.acreditaciones?.servicios?.length > 0 ? (validation.values.acreditaciones?.servicios?.filter(res => res?.id === data?.id).length > 0 ? 'bg-o2o-gris-calido-hover' : '') : ''}`}
                                    onClick={() => agregarInteresesPersonales(data?.id, data.categoria_id)}
                                >
                                    {data.nombre}
                                </button>
                            )
                        })}
                    </>
                    :
                    <>
                        {
                            item?.acreditaciones?.servicios
                                ?.filter(servicio => servicio.categoria_id === 7) // Filtrar por categoria_id
                                .map((servicio, index) => (
                                    <span
                                        key={index}
                                        className="flex items-center bg-[#E5E5E5] px-4 rounded-full border border-black h-6"
                                    >
                                        {servicio.nombre}
                                    </span>
                                ))
                        }
                    </>
                }

            </div>
        </>
    );
}

export default AreasEspecial;