//REGISTER
export const POST_LOGIN_TOKEN = "/login"
export const LOG_OUT = "/logout"
export const RESET_PASSWORD = '/reset'
export const RESET_CONFIRM = '/account-confirm'
export const VALIDA_TOKEN = '/account-valida-token'

//HOME
export const GET_DATA_HOME = 'operacion-cms/home'

//uSERS
export const USERS_RESOURCE = '/users'
export const USUARIOS_RESOURCE = '/operacion-perfiles/usuarios'
//clients
export const CLIENTS_RESOURCE = 'operacion-perfiles/clientes'
//pacientes
export const PACIENTES_RESOURCE = 'operacion-perfiles/pacientes'
// plans
export const PLANS_RESOURCE = '/cms/plans'
export const PLANS_ACTIVATE = '/cms/plan-action'
//PAYMETS
export const PAYMENTS_RESOURCE = '/cms/payments'
//catalogos
export const GET_PREFIXES = '/catalogs-cms/prefixes'
export const GET_MODULES = '/catalogs-cms/models'
export const GET_PAYMENTS_PERIODS = '/catalogs-cms/payment-periods'
export const GET_STATUSES = '/catalogs-cms/statuses'
export const GET_NIVELES = '/catalogos-registro/niveles'
export const GET_DECLINAR = '/catalogos-perfiles/motivos-cancelacion'
//sOLICITUDES DE TRABAJO
export const ACEPTAR_DECLINAR_SOLICITUD = '/operacion-cms/solicitud-aceptar-declinar'

export const GET_INTERESES = '/catalogos-registro/intereses'
export const GET_ESPECIALIDADES = '/catalogos-registro/especialidades'
export const GET_CUIDADOS = '/catalogos-registro/cuidados'
export const GET_ENTIDADES = '/catalogos-registro/entidades'
export const GET_HABILIDADES = '/catalogos-registro/habilidades'
export const GET_OFERTA_SERVICIOS = '/catalogos-registro/oferta-servicios'
export const GET_TIEMPOS= '/catalogos-registro/turnos-tiempos'
export const NIVELES_RESOURCE = '/catalogos-registro/niveles'
export const PAISES = '/catalogos-registro/paises'
export const MUNICIPIOS = '/catalogos-registro/municipios'
export const ESTADOS = '/catalogos-registro/estados'
export const CPS = '/catalogos-registro/cps'
export const GET_MOTIVOS_CANCELACION = '/catalogos-perfiles/motivos-cancelacion'
export const SEXOS_GENEROS = '/catalogos-registro/generos-sexos'
export const GET_MODULO = '/catalogos-registro/modulos'

// enfermero
export const ENFERMERO_RESOURCE = 'operacion-perfiles/enfermeros'
export const ENFERMERO_FILTERS_CMS = 'operacion-cms/enfermeros-filters'
//FACTURAS_ENFERMERO_RESOURCE
export const FACTURAS_ENFERMERO_RESOURCE = '/operacion-perfiles/facturas-servicios'
//Servicios
export const SERVICIO_RESOURCE = 'operacion-perfiles/servicios'

//bANCA
export const DISPERSION_FACTURAS_ENFERMERO = 'operacion-cms/banca/pago-turnos'
export const GET_PAYOUTS = 'operacion-cms/banca/payouts'
export const GET_BANCO_DATA = '/operacion-cms/banca/data'
export const GET_BANCO_DATA_TOTALS = '/operacion-cms/banca/data-totals'
export const GET_COMISION = '/operacion-cms/banca/comision'
export const CONFIRMAR_PAGO =  '/operacion-cms/banca/confirmar-pago'
//Pagos de cliente
export const SERVICIO_FACTURAR = '/operacion-cms/banca/facturar-servicio'
export const GET_FACTURA = '/operacion-cms/banca/factura-get'




