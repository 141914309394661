import { toast } from "react-toastify";

export const warningToast = (mensaje) => {
    toast.warn(
        mensaje,
        {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        }
    );
}

export const successToast = (mensaje) => {
    toast.success(
        mensaje,
        {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        }
    );
}

export const warningToastCenter = (mensaje) => {
    toast.warn(
        mensaje,
        {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        }
    );
}

export const dangerToast = (mensaje) => {
    toast.error(
        mensaje,
        {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        }
    );
}

export const infoToastCenter = (mensaje, position = 'top-center') => {
    toast.info(
        mensaje,
        {
            position: position,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        }
    );
}