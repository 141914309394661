import { combineReducers } from "redux"
// Authentication
//import Login from "./auth/login/reducer"
//sucursales
import Usuario from "./users/registro/reducer"
import Login from "./auth/login/reducer"
import Loader from "./loading/reducer"
import PacientesState from "./pacientes/reducer"
//Configuracion de acciones de titulos y titulo
//import Titles from "./titles/reducer"

const rootReducer = combineReducers({
  Login,
  Usuario,
  Loader,
  PacientesState
})

export default rootReducer
