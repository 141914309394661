import React, { useState } from "react";
import { FaEye } from "react-icons/fa";
import PreviewPDF from "../../modal/PreviewPdf";
import CustomButton from "../../form/CustomButton";

const Estudios = ({ item, editItem, onDelete, validation }) => {
    const [modalPdf, setModalPdf] = useState({ show: false, url: '' });
    return (
        <>
            <div className="flex flex-col">
                <div className="flex flex-row items-center w-full pl-6 space-x-2">
                    <p className="font-bold text-sm whitespace-nowrap">Formación académica</p>
                    <hr className="flex-1 ml-2" />
                </div>
                {item?.acreditaciones?.formacion_academica.map((e, i) => (
                    <div key={i} className="flex flex-row justify-between pl-6 mt-4">
                        {/* Periodo */}
                        <div className="flex flex-col flex-1">

                            <p className="font-medium text-base">
                                {e.fecha_inicio
                                    ? new Date(e.fecha_inicio).getFullYear()
                                    : "Año no disponible"}{" "}
                                -{" "}
                                {e.fecha_fin
                                    ? new Date(e.fecha_fin).getFullYear()
                                    : "Año no disponible"}
                            </p>

                            <p className="text-xs">Fecha final</p>
                        </div>

                        {/* Institución */}
                        <div className="flex flex-col flex-1">

                            <p className="font-medium text-base">{e.institucion}</p>

                            <p className="text-xs">Institución</p>
                        </div>

                        {/* Estudios */}
                        <div className="flex flex-col flex-1">

                            <p className="font-medium text-base">{e.especialidad_nombre}</p>

                            <p className="text-xs">Estudios</p>
                        </div>

                        {/* Íconos de agregar/ver documento y eliminar */}
                        <div className="flex flex-col flex-1 justify-center items-center">

                            {e.comprobante &&
                                <CustomButton onClick={() => setModalPdf({ firma: true, show: true, url: e.comprobante })}>
                                    Ver Archivo
                                </CustomButton>
                            }
                        </div>
                    </div>
                ))}
                <PreviewPDF modal={modalPdf} setModal={setModalPdf} />
            </div>
        </>
    );
};

export default Estudios;
