import React, { useEffect, useState } from "react";
import BaseModalAnimated from "../../components/modal/BaseModalAnimated";
import CustomButton from "../../components/form/CustomButton";
import { enfermeroFiltersCms, getAreasEsp, getEspecialidades, getHabilidades, getNiveles, getTurnos } from "../../helpers/configRoutes/backend_helper";

const Filters = ({ modal, setModal, setItems, items }) => {
    const [loading,setLoading] = useState(false)
    const [especialidad, setEspecialidad] = useState([]);
    const [niveles, setNiveles] = useState([]);
    const [turnos, setTurnos] = useState([]);
    const [tiempos, setTiempos] = useState([]);
    const [habilidades, setHabilidades] = useState([]);
    const [areas, setAreas] = useState([]);
    const daysOfWeek = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"];
    const [filters, setFilters] = useState({
        edad: 18,
        especialidad: [],
        nivel_id: '',
        turno_id: '',
        tiempo_id: '',
        habilidades: [],
        areas: [],
        disponibilidad: [], // Por ejemplo, para los días seleccionados
    });

    useEffect(() => {
        const getCatalogos = async () => {
            try {
                const response = await getEspecialidades('get', {});
                if (response && Array.isArray(response.especialidades)) {
                    setEspecialidad(response.especialidades);
                }
                const responseNiveles = await getNiveles('get', {});
                if (responseNiveles && Array.isArray(responseNiveles.niveles)) {
                    setNiveles(responseNiveles.niveles);
                }
                const responseTurnos = await getTurnos('get', {});
                if (responseTurnos && Array.isArray(responseTurnos.turnos)) {
                    setTurnos(responseTurnos.turnos);
                }
                if (responseTurnos && Array.isArray(responseTurnos.tiempos)) {
                    setTiempos(responseTurnos.tiempos);
                }
                const responseHabilidades = await getHabilidades('get', {});
                if (responseHabilidades && Array.isArray(responseHabilidades.habilidades)) {
                    setHabilidades(responseHabilidades.habilidades);
                }
                const responseAreasEsp = await getAreasEsp('get', {});
                if (responseAreasEsp && Array.isArray(responseAreasEsp.categorias)) {
                    setAreas(responseAreasEsp.categorias);
                }
            } catch (error) {
                console.error("Error al obtener especialidades:", error);
            }
        };
        getCatalogos();
    }, []);
    
    const handleMultiSelect = (key, value) => {
        setFilters((prevFilters) => {
            const isSelected = prevFilters[key].includes(value);
            const updatedValues = isSelected
                ? prevFilters[key].filter((item) => item !== value) // Si ya está seleccionado, lo quitamos
                : [...prevFilters[key], value]; // Si no está, lo agregamos
            return { ...prevFilters, [key]: updatedValues };
        });
    };

    const save = async () => {
        setLoading(true)
        const response = await enfermeroFiltersCms(filters)
        if(response.status){
            setItems(response.enfermeros)
            setLoading(false)
            setModal({...modal,show:false})
        }
        setLoading(false)
    }
     // Comprobación si un valor está seleccionado
     const isSelected = (key, value) => filters[key].includes(value);

    return (
        <>
            <BaseModalAnimated modal={modal} setModal={setModal}>
                <div className="p-4 bg-white shadow-lg rounded-lg max-w-3xl mx-auto">
                    <h2 className="text-lg font-semibold mb-4">Filtro personalizado</h2>

                    {/* Contenedor principal dividido en 2 columnas */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

                        {/* Primera columna (todos los filtros aquí) */}
                        <div>
                            <div className="mb-4">
                                <label className="block font-semibold mb-2">Edad</label>
                                <input
                                    type="range"
                                    min="18"
                                    max="80"
                                    value={filters.edad}
                                    onChange={(e) => setFilters({ ...filters, edad: e.target.value })}
                                    className="w-full"
                                />
                                <div className="text-center text-sm mt-2">{filters.edad} años</div>
                            </div>
                            <div className="mb-4">
                                <label className="block font-semibold mb-2">Estado civil</label>
                                <div className="flex gap-2 flex-wrap">
                                    <CustomButton className={`btn-filter ${filters.estadoCivil == 'Soltero' ? 'bg-o2o-purple' : ''}`} onClick={() => setFilters({ ...filters, estadoCivil: 'Soltero' })}>Soltero</CustomButton>
                                    <CustomButton className={`btn-filter ${filters.estadoCivil == 'Casado' ? 'bg-o2o-purple' : ''}`} onClick={() => setFilters({ ...filters, estadoCivil: 'Casado' })}>Casado</CustomButton>
                                    <CustomButton className={`btn-filter ${filters.estadoCivil == 'Divorciado' ? 'bg-o2o-purple' : ''}`} onClick={() => setFilters({ ...filters, estadoCivil: 'Divorciado' })}>Divorciado</CustomButton>
                                    <CustomButton className={`btn-filter ${filters.estadoCivil == 'Viudo' ? 'bg-o2o-purple' : ''}`} onClick={() => setFilters({ ...filters, estadoCivil: 'Viudo' })}>Viudo</CustomButton>
                                </div>
                            </div>
                            <div className="mb-4">
                                <label className="block font-semibold mb-2">Especialidades</label>
                                <div className="grid grid-cols-2 gap-2">
                                    {especialidad.map((item, index) => (
                                        <CustomButton
                                            key={index}
                                            onClick={() => handleMultiSelect("especialidad", item.id)}
                                            className={`btn-filter text-xs ${isSelected("especialidad", item.id) ? "bg-o2o-purple" : ""
                                                }`}
                                        >
                                            {item.nombre}
                                        </CustomButton>
                                    ))}
                                </div>
                            </div>

                            {/* Disponibilidad */}
                            <div className="mb-4">
                                <label className="block font-semibold mb-2">Dias</label>
                                <div className="flex flex-wrap gap-2">
                                    {daysOfWeek.map((day, index) => (
                                        <CustomButton
                                            key={`day-${index}`}
                                            onClick={() => handleMultiSelect("disponibilidad", day)}
                                            className={`btn-filter text-xs ${isSelected("disponibilidad", day) ? "bg-o2o-purple" : ""
                                                }`}
                                        >
                                            {day}
                                        </CustomButton>
                                    ))}
                                </div>
                            </div>
                            <div className="mb-4">
                                <label className="block font-semibold mb-2">Turno</label>
                                <div className="flex flex-wrap gap-2">
                                    {turnos.length > 0 ? (
                                        turnos.map((item, index) => (
                                            <CustomButton
                                                key={index}
                                                onClick={() => setFilters({ ...filters, turno_id: item.id })}
                                                className={`btn-filter text-xs ${filters.turno_id == item.id ? 'bg-o2o-purple' : ''}`}
                                            >
                                                {item.nombre}
                                            </CustomButton>
                                        ))
                                    ) : (
                                        <p>No hay turnos disponibles.</p>
                                    )}
                                </div>
                            </div>
                            <div className="mb-4">
                                <label className="block font-semibold mb-2">Tiempos</label>
                                <div className="flex flex-wrap gap-2">
                                    {tiempos.length > 0 ? (
                                        tiempos.map((item, index) => (
                                            <CustomButton
                                                key={`tiempo-${index}`}
                                                className={`btn-filter ${filters.tiempo_id == item.id ? 'bg-o2o-purple' : ''}`}
                                                onClick={() => setFilters({ ...filters, tiempo_id: item.id })}
                                            >
                                                {item.nombre}
                                            </CustomButton>
                                        ))
                                    ) : (
                                        <p>No hay tiempos disponibles.</p>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Segunda columna */}
                        <div>
                            <div className="mb-4">
                                <label className="block font-semibold mb-2">Habilidades profesionales</label>
                                <div className="grid grid-cols-2 gap-2">
                                    {habilidades.map((item, index) => (
                                        <CustomButton
                                            key={index}
                                            onClick={() => handleMultiSelect("habilidades", item.id)}
                                            className={`btn-filter text-xs ${isSelected("habilidades", item.id) ? "bg-o2o-purple" : ""
                                                }`}
                                        >
                                            {item.nombre}
                                        </CustomButton>
                                    ))}
                                </div>
                            </div>
                            <div className="mb-4">
                                <label className="block font-semibold mb-2">Niveles</label>
                                <div className="grid grid-cols-2 gap-2">
                                    {niveles.length > 0 ? (
                                        niveles.map((item, index) => (
                                            <CustomButton
                                                key={index}
                                                onClick={() => setFilters({ ...filters, nivel_id: item.id })}
                                                className={`btn-filter text-xs ${filters.nivel_id == item.id ? 'bg-o2o-purple' : ''}`}
                                            >
                                                {item.nombre}
                                            </CustomButton>
                                        ))
                                    ) : (
                                        <p>No hay niveles disponibles.</p>
                                    )}
                                </div>
                            </div>

                            {/* Áreas Especiales */}
                            {areas.map((c, i) => (
                                <div className="mb-4" key={i}>
                                    <label className="block font-semibold mb-2">{c.nombre}</label>
                                    <div className="grid grid-cols-2 gap-2">
                                        {c.ofertas.map((item, index) => (
                                            <CustomButton
                                                key={index}
                                                onClick={() => handleMultiSelect("areas", item.id)}
                                                className={`btn-filter text-xs ${isSelected("areas", item.id) ? "bg-o2o-purple" : ""
                                                    }`}
                                            >
                                                {item.nombre}
                                            </CustomButton>
                                        ))}
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                    <div className="flex items-center justify-center p-6 rounded-b border-slate-200">
                        <CustomButton
                            loading={loading}
                            textLoad={'Generando resultados'}
                            type={"button"}
                            onClick={() => save()}
                            className="w-full lg:w-[30%] bg-o2o-gray-title"
                        >
                            Aplicar Filtros
                        </CustomButton>
                    </div>
                </div>

            </BaseModalAnimated>
        </>
    );
}

export default Filters;
