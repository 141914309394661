import React, { useEffect, useState } from "react";
import BaseModalAnimated from "../../components/modal/BaseModalAnimated";
import CustomButtonOval from "../../components/form/CustomButtonOval";
import CustomButton from "../../components/form/CustomButton";
import { getModules, getDeclinar, aceptarDeclinarSolicitud } from "../../helpers/configRoutes/backend_helper";
import { useNavigate, useParams } from "react-router-dom";
import CustomInput from "../../components/form/CustomInput";
import { warningToast } from "../../helpers/Toast";

const DeclinarModal = ({ modal, setModal, validation }) => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [motivos, setMotivos] = useState([]);
    const [motivoText, setMotivoText] = useState('');
    const [selectedMotivos, setSelectedMotivos] = useState([]); // Estado para los motivos seleccionados
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const getMotivosData = async () => {
            const response = await getDeclinar('get', { params: { model: (modal.model || 'SolicitudTrabajo') } });
            if (response.status) {
                setMotivos(response.motivos);
            }
        }
        getMotivosData();
    }, []);

    const handleRowChange = (motivoId) => {
        setSelectedMotivos((prevSelected) => {
            if (prevSelected.includes(motivoId)) {
                return prevSelected.filter((id) => id !== motivoId); // Desmarca si ya está seleccionado
            } else {
                return [...prevSelected, motivoId]; // Agrega si no está seleccionado
            }
        });
    };

    const save = async () => {
        setLoading(true);
      
        // Validación si no se ha seleccionado ningún motivo
        if (selectedMotivos.length === 0) {
          warningToast('Error: No se ha seleccionado ningún motivo');
          setLoading(false);
          return false;
        }
        // Validación si "Otro. Especifica" está seleccionado pero motivoText está vacío
        if (selectedMotivos.includes(20) && !motivoText) {
          warningToast('Error: Especificar el motivo es necesario cuando seleccionas "Otro. Especifica"');
          setLoading(false);
          return false;
        }
      
        // Petición al servidor
        const response = await aceptarDeclinarSolicitud({
          action: modal.reactivar ? 4:2,
          motivos: selectedMotivos,
          enfermero_id: id,
          motivo: motivoText
        });
      
        // Navegación si la petición es exitosa
        if (response.status) {
          navigate(`/registros-pendientes`);
        }
      
        setLoading(false);
      };
      

    return (
        <>
            <BaseModalAnimated modal={modal} setModal={setModal}>
                <div className="p-4">
                    <h2 className="text-center text-[22px]">{modal.reactivar ? 'Estás a punto de reactivar la cuenta de':'Estás a punto de declinar el ingreso de'} {validation?.values?.nombre || modal.item.nombre}</h2>
                    <p className="text-[22px]">Selecciona la razón o razones de {modal.reactivar ? 'reactivar':'rechazo'}:</p>
                </div>
                {/* Lista de motivos */}
                <div className="p-4">
                    {motivos && motivos.length > 0 ? (
                        motivos.map((row) => (

                            <label
                                key={row.id}
                                className="flex items-center my-2 cursor-pointer group"
                            >
                                <input
                                    type="checkbox"
                                    name="row"
                                    value={row.id}
                                    checked={selectedMotivos.includes(row.id)}
                                    onChange={() => handleRowChange(row.id)}
                                    className="hidden"
                                />
                                <div
                                    className={`relative w-6 h-6 border-4 rounded flex items-center justify-center transition-all duration-300 ${selectedMotivos.includes(row.id)
                                        ? 'border-blue-500' // Color más marcado cuando está seleccionado
                                        : 'border-gray-400' // Color del borde cuando no está seleccionado
                                        }`}
                                >
                                    {selectedMotivos.includes(row.id) && (
                                        <div className="w-3.5 h-3.5 bg-blue-500 rounded transition-all duration-300"></div>
                                    )}
                                </div>
                                <span className="ml-3 text-gray-700 group-hover:text-blue-500 transition-colors duration-300">
                                    {row.motivo}
                                </span>
                            </label>
                        ))
                    ) : (
                        <p>No hay datos disponibles para mostrar.</p>
                    )}
                    <CustomInput
                        label={'Motivo'}
                        type={'text'}
                        isTextArea={true}
                        onChange={(e) => setMotivoText(e.target.value)}
                    />
                </div>

                {/* Pie del modal */}
                <div className="flex items-center justify-center p-6 rounded-b border-slate-200">
                    <CustomButton

                        type={"button"}
                        onClick={() => setModal({ ...modal, show: false })}
                        className="w-full lg:w-[30%] bg-o2o-gray-title"
                    >
                        Regresar
                    </CustomButton>
                    <CustomButton
                        loading={loading}
                        textLoad={'Procesando'}
                        type={"success"}
                        onClick={save}
                        className="w-full lg:w-[30%] bg-o2o-black"
                    >
                        Continuar
                    </CustomButton>
                </div>
            </BaseModalAnimated>
        </>
    )
}

export default DeclinarModal;
