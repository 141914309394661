import React, { useState } from "react";
import CustomInput from "../form/CustomInput";
import CustomSelect from "../form/CustomSelect";
import FileInput from "../form/FileInput";
import CustomButton from "../form/CustomButton";
import PreviewPDF from "../../components/modal/PreviewPdf"

const GeneralInfo = ({ item, children, validation, editItem }) => {
  const [modalPdf, setModalPdf] = useState({ show: false, url: '' });
  const c_estadoCivil = [
    { id: 'Soltero', nombre: 'Soltero' },
    { id: 'Casado', nombre: 'Casado' },
    { id: 'Divorciado', nombre: 'Divorciado' },
    { id: 'Viudo', nombre: 'Viudo' }
  ]
  return (
    <>
      <div className="flex flex-row w-full justify-between">
        <div className="flex flex-col items-center w-1/6">
          {editItem ? (
            <>
              <img
                src={validation.values.perfilImg}
                alt="nurce img"
                className="object-fill h-24 rounded-full"
              />
              <FileInput
                name="perfilImg"
                validation={validation}
                format={'img'}
                className="mt-2" // Margen superior para separar del img
              />
            </>
          ) : (
            <img
              src={item.perfilImg}
              alt="nurce img"
              className="object-fill h-24 rounded-full"
            />
          )}
        </div>

        <div className="flex flex-col w-5/6">
          <div className="flex flex-row justify-between">
            {editItem ? (
              <>
                {/* Inputs for editing */}
                <div className="flex flex-col flex-1">
                  <CustomInput
                    name="nombre"
                    value={validation.values.nombre || ""}
                    error={validation.errors?.nombre}
                    placeholder={"Nombre(s)"}
                    type={"text"}
                    onChange={validation.handleChange}
                    className="font-medium text-lg"
                  />
                  <p className="text-sm">Nombre</p>
                  <CustomInput
                    name="curp"
                    value={validation.values.curp || ""}
                    error={validation.errors?.curp}
                    placeholder={"CURP"}
                    type={"text"}
                    onChange={validation.handleChange}
                    className="font-medium text-lg"
                  />
                  <p className="text-sm">CURP</p>
                  <CustomInput
                    name="email"
                    placeholder={"example@mail.com"}
                    value={validation.values.email || ""}
                    error={validation.errors?.email}
                    type={"email"}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    className="font-medium text-lg"
                  />
                  <p className="text-sm">Mail</p>
                </div>
                <div className="flex flex-col flex-1">
                  <CustomInput
                    name="apellidos"
                    value={validation.values.apellidos || ""}
                    error={validation.errors?.apellidos}
                    placeholder={"Apellidos"}
                    type={"text"}
                    onChange={validation.handleChange}
                    className="font-medium text-lg"
                  />
                  <p className="text-sm">Apellidos</p>
                  <CustomSelect
                    name="estadoCivil"
                    options={c_estadoCivil}
                    value={validation.values.estadoCivil || ''}
                    error={validation.errors?.estadoCivil}
                    onChange={validation.handleChange}
                  />
                  <p className="text-sm">Estado civil</p>
                  <div className="flex flex-col flex-1 justify-center items-center">
                    <div className="flex items-center space-x-4">
                      {/* Ícono para seleccionar archivo */}
                      <FileInput
                        format={'pdf'}
                        validation={validation}
                        name={`identificacion`}
                        nameInput={'Subir identificación'}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col flex-1">
                  <CustomInput
                    name="movil"
                    placeholder={"1234567890"}
                    value={validation.values.movil || ""}
                    error={validation.errors?.movil}
                    type={"text"}
                    onChange={(e) =>
                      validation.setFieldValue(
                        "movil",
                        parseInt(e.target.value)
                      )
                    }
                    onBlur={validation.handleBlur}
                    className="font-medium text-lg"
                  />
                  <p className="text-sm">Móvil</p>
                  <CustomInput
                    name="fechaNacimiento"
                    value={validation.values.fechaNacimiento || ""}
                    error={validation.errors?.fechaNacimiento}
                    placeholder={"Fecha de nacimiento"}
                    type={"date"}
                    onChange={validation.handleChange}
                    className="font-medium text-lg"
                  />
                  <p className="text-sm">Fecha de nacimiento</p>
                </div>
                <div className="flex flex-col flex-1" >
                  {children}

                  

                </div>
              </>
            ) : (
              <>
                {/* Display mode */}
                <div className="flex flex-col flex-1">
                  <p className="font-medium text-lg">{item?.nombre}</p>
                  <p className="text-sm">Nombre</p>
                  <p className="font-medium text-lg">{item?.curp || 'No registrado'}</p>
                  <p className="text-sm">CURP</p>
                </div>
                <div className="flex flex-col flex-1">
                  <p className="font-medium text-lg">{item?.apellidos}</p>
                  <p className="text-sm">Apellidos</p>
                  <p className="font-medium text-lg">{item?.estadoCivil || 'No registrado'}</p>
                  <p className="text-sm">Estado civil</p>
                </div>
                <div className="flex flex-col flex-1">
                  <p className="font-medium text-lg">{item?.movil || "No registrado"}</p>
                  <p className="text-sm">Móvil</p>
                  <p className="font-medium text-lg">{item?.edad}</p>
                  <p className="text-sm">Edad</p>
                </div>
                <div className="flex flex-col flex-1">
                  {children}
                  
                  <p className="font-medium text-lg">{item?.email || 'No registrado'}</p>
                  <p className="text-sm">Mail</p>
                  <p className="font-medium text-lg">{item?.created_at}</p>
                  <p className="text-sm">Registrado desde</p>
                  {item.identificacion &&
                    <CustomButton onClick={() => setModalPdf({ firma: true, show: true, url: item.identificacion })}>
                      Ver Archivo
                    </CustomButton>
                  }
                </div>
              </>
            )}
          </div>
        </div>
        <PreviewPDF modal={modalPdf} setModal={setModalPdf} />
      </div>
    </>
  );
};

export default GeneralInfo;
