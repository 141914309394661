import React from 'react';

const CustomSelect = ({value,name,options,handleChange,label,labelClassName,className,error,holder,...props}) => {
    const itemClassName = `
        border pl-4 rounded-md h-12
        ${error ? 'border-red-600' : 'border-black'}
        ${props.disabled ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-mts-gray'}
        ${className}
    `;
    const labelBtnClassName = `border-2 border-dark w-full ${labelClassName}`;

    const ErrorMensaje = () => {
        if (error) {
            return <span className="text-red-600">{error}</span>;
        }
        return null;
    };

    return (
        <div className="flex flex-col w-full py-1">
            <label className={labelClassName}>{label}</label>
            <select
                name={name}
                value={value} // Control the selected value
                className={itemClassName}
                onChange={handleChange}
                disabled={props.disabled} // Add disabled attribute
                {...props}
            >
                <option value="">{holder ?? 'Select'}</option> {/* Set default empty option */}
                {options.map((item, index) => (
                    <option value={item.id} key={index}>
                        {item.nombre}
                    </option>
                ))}
            </select>
            <ErrorMensaje />
        </div>
    );
};

export default CustomSelect;
