import React, { useEffect, useState } from "react";
import GenericCard from "../../components/cards/GenericCard";
import { getDataHome } from "../../helpers/configRoutes/backend_helper";
import { formatCurrencyUsd } from "../../helpers/Formatos";
const Home = () => {
    const [home, setHome] = useState({});
    useEffect(() => {
        const getHome = async () => {
            const response = await getDataHome('get', {});
            setHome(response.data);
        };
        getHome();
    }, []);
    return (
        <>
            <div className="flex flex-col w-full p-4">
                {/* Título */}
                <div className="flex flex-row">
                    <p className="font-bold text-xl">Bienvenido a One2One Nurses </p>
                </div>
                {/* Secciones */}
                <div className="grid grid-cols-3 gap-4 mt-6">
                    <div>
                        <GenericCard name="Monto facturado" text={formatCurrencyUsd(home.montoFacturado || 0)} />
                    </div>
                    <div>
                        <GenericCard name="Total de usuarios (Global)" text={home.totalUsuarios || 0} />
                    </div>
                    <div>
                        <GenericCard name="Opiniones pendientes" text={home.nuevasOpiniones || 0} subtext={`${home.nuevasOpinionesPorcentaje||0}% última hora`} />
                    </div>
                    <div>
                        <GenericCard name="Servicios activos" text={home.serviciosActivos} subtext={`+${home.serviciosActivosUltimaHora}% última hora`} />
                    </div>
                    <div>
                    <GenericCard name="Solicitudes de servicios " text={home.solicitudServicio} subtext={`+${home.solicitudServicioUltimaHora}% última hora`} />
                    </div>
                    <div>
                        <GenericCard name="Servicios completados" text={home.serviciosConcluidos} subtext={`+${home.serviciosConcluidosUltimaHora}% última hora`} />
                    </div>
                    <div>
                        <GenericCard name="Nuevos registros de pacientes" text={home.pacientesUltimaSemana} subtext={`+${home.pacientePorcentaje}% última semana`} />
                    </div>
                    <div>
                        <GenericCard name="Nuevos registros de enfermeros" text={home.enfermerosUltimaSemana} subtext={`+${home.enfermerosPorcentaje}% última semana`} />
                    </div>
                    <div>
                        <GenericCard name="Enfermeros pendientes de aprobación." text={home.enfermerosUltimaSemanaSolicitud} subtext={`+${home.enfermerosPorcentajeSolicitud}% última semana`} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;
